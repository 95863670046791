$(document).ready(function () {
  const $root = $('.js-media-gallery');
  const $slideMediaModal= $('.js-media-modal');
  const $modal = $('#exampleModalCenter');
  const $cardFeatured = $root.find('.js-featured');
  const $cardDefault = $root.find('.js-default');
  const gutter = 10;

  $modal.on('show.bs.modal', function (event) {
    const img = $(event.relatedTarget) ;
    const recipient = img.data('order') ;

    if (hasSlick()) {
      $slideMediaModal.slick('slickGoTo', recipient);
    }
  });

  $modal.on('shown.bs.modal', function (event) {
    const img = $(event.relatedTarget) ;
    const recipient = img.data('order') ;

    slickSlideOnModal(recipient);
  });

  const hasSlick = () => $slideMediaModal.hasClass('slick-initialized');

  const showSlideNumber = (slideNumber) => {
    const slideCount = $slideMediaModal.slick('getSlick').slideCount;
    let slideText  = $('.js-modal-title').val();

    slideText = slideText.replace('$totalSlide', slideCount);
    slideText = slideText.replace('$current', slideNumber);

    $('.js-modal-body-title').html(slideText);
  };

  const slickSlideOnModal = (slideNumber) => {
    if (hasSlick()) {
      return;
    }

    $slideMediaModal.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: $('.js-slick-prev'),
      nextArrow: $('.js-slick-next'),
      initialSlide: slideNumber,
    });

    $slideMediaModal.on('afterChange', function(event, slick, currentSlide ){
      showSlideNumber(currentSlide + 1);
    });

    showSlideNumber(slideNumber + 1);

    $slideMediaModal.removeClass('hidden');
  };

  const setSizeDefault = () => {
    let heightCardFeatured = $cardFeatured.outerHeight();
    let heightCardDefault;

    if ($(window).width() >= 768) {
      heightCardDefault = (heightCardFeatured - gutter)/2;
    } else {
      heightCardDefault = heightCardFeatured;
    }
    $cardDefault.height(heightCardDefault);
  };

  setSizeDefault();

  const packeryOption = {
    itemSelector: '.grid-item',
    gutter: '.grid-sizer',
    transitionDuration: '0.5s',
    percentPosition: true,
  };

  const $grid = $root.packery(packeryOption);

  $grid.on('layoutComplete', function() {
    setSizeDefault();
  });

  $(window).on('resize', window.debounce(function() {
    setSizeDefault();
    $grid.packery();
  }, 1000));
});
