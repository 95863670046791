$(document).ready(function () {
  const $bgImgTextComponents = $('.bg-img-text');
  if (!$bgImgTextComponents.length) return;

  $bgImgTextComponents.each(function () {
    const $this = $(this);
    const slideUpText = $this.find('.js-slide-up');
    const slideDownText = $this.find('.js-slide-down');
    const slideInAt = window.innerHeight / 2;

    $(window).scroll(function() {
      const slideInPosition = $this.offset().top;
      const scrollPosition = $(window).scrollTop();

      if (slideInPosition < scrollPosition + slideInAt) {
        slideUpText.addClass('slide-up');
        slideDownText.addClass('slide-down');
      } else {
        slideUpText.removeClass('slide-up');
        slideDownText.removeClass('slide-down');
      }
    });
  });
});
