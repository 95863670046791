$(document).ready(function () {
  if ($('.rich-text iframe').length > 0) {
    const $el = $('.rich-text iframe');
    $el.each((i, elm) => {
      const $elm = $(elm),
        elmWidth = $elm.attr('width') ? $elm.attr('width') : 0,
        elmHeight = $elm.attr('height') ? $elm.attr('height') : 0;
      let elmAR = 0,
        elmStyle = '';
      if (elmWidth > 0 && elmHeight > 0) {
        elmAR = (elmHeight / elmWidth) * 100;
        elmStyle = `style = "padding-bottom: ${elmAR}%"`;
      }

      $elm.wrap(`<div class="video" ${elmStyle}></div>`);
    });
  }

  function richTextTable() {
    const $el = $('.rich-text table');
    $el.each((i, elm) => {
      const $elm = $(elm);

      if ($elm.hasClass('table-primary--stack')) {
        const $headers = $elm.find('> thead th');

        $elm.find('> tbody > tr').each(function(idx, row) {
          const $row = $(row);

          $row.children('td').each(function(idx, td) {
            const $td = $(td);
            $td.attr('data-header', $headers.get(idx).innerText);
          });
        });
      }

      if (!$elm.parent().hasClass('table-responsive')) {
        const $tableWrapper = $('<div class="table-responsive"></div>').append($elm.clone());

        if ($elm.hasClass('table-primary--full')) {
          $tableWrapper.addClass('table-responsive--full');
        }

        if ($elm.hasClass('table-primary--stack')) {
          $tableWrapper.addClass('table-responsive--stack');
        }

        $elm.replaceWith($tableWrapper);
      }

    });
  }

  richTextTable();
});
