$(document).ready(function () {
  const $seachClose = $('.js-nav-search-close');
  const $searchOpen = $('.js-nav-search-open');
  const $subMenu = $('.js-nav-search-submenu');
  const $subMenuContainer = $('.js-sub-menu-container');
  const $subMenuTemplate = $('#sub-menu-template');
  const $navbarSupportedContent = $('#navbarSupportedContent');
  const $hamburger = $('.js-hamburger');
  const $login = $('.js-login');
  const $navigationWrap = $('.js-navigation-wrap');
  const $popoverLogin = $('[data-toggle="popover"]');
  const $popoverLoginMobile = $('[data-toggle="popover-mobile"]');
  const $popoverLoginWrapper = $('.js-login-arrow-wrapper');
  const isActive = $popoverLoginWrapper.first().hasClass('active');
  const $navSearchLinks = $('.js-nav-search-links');
  const $navSearchForm = $('.js-nav-search-form');
  const $takeChargeToggle = $('.a-nav-search__takecharge');
  const $menuItem = $('.js-sub-menu-item');
  const $mainMenu = $('.a-nav-search__collapse');
  const $menuClose = $('.js-submenu-close');
  const $listItem = $('.js-submenu-open');

  populateSubMenu();

  $hamburger.on('click', () => {
    $('body').toggleClass('hidden-scroll');
  });

  function populateSubMenu() {
    const $activeSubMenuLinks = $('.js-sub-menu-item.active').find('.js-nav-search-submenu-list a');
    const $linkTemplate = $($subMenuTemplate.html());

    $activeSubMenuLinks.each(function (index, element) {
      const $originLink = $(element);
      const $newLink = $linkTemplate.clone();
      const $newAElement = $newLink.find('a');

      if ($originLink.hasClass('sub-active')) {
        $newAElement.addClass('sub-active');
      }

      $newAElement.attr('href', $originLink.attr('href'));
      $newAElement.html($originLink.html());
      $subMenuContainer.append($newLink);
    });
  }

  $searchOpen.on('click', (event) => {
    event.stopPropagation();
    const $this = $(event.target);
    const $container = $this.parents('.js-nav-search-container');

    $container.find('.js-nav-search-form').show();
    $container.find('.js-nav-search-links').hide();
    $container.find('#navbarSupportedContent .js-login').removeClass('d-lg-block');
    $container.find('#navbarSupportedContent').removeClass('navbar-collapse');
    $container.find('.a-nav-search__collapse').hide();
    $container.find('.a-nav-search__mobile-top').hide();
    $this.hide();
  });

  $seachClose.on('click', (event) => {
    event.stopPropagation();
    const $this = $(event.target);
    const $container = $this.parents('.js-nav-search-container');

    $container.find('.js-nav-search-form').hide();
    $container.find('.js-nav-search-links').show();
    $container.find('#navbarSupportedContent .js-login').addClass('d-lg-block');
    $container.find('#navbarSupportedContent').addClass('navbar-collapse');
    $container.find('.a-nav-search__collapse').show();
    $container.find('.a-nav-search__mobile-top').show();
    $searchOpen.show();
  });

  $subMenu.on('click', function (e) {
    if ($(window).width() >= window.screenSizes.mobile) {
      return;
    }

    e.preventDefault();

    const $self = $(this);
    $self.toggleClass('expanded');
    const $container = $self.parents('.js-sub-menu-item');
    $container.find('.js-nav-search-submenu-list').slideToggle(350);
    $container.toggleClass('active');
  });

  $hamburger.on('click', function () {
    const isSubMenuOpen = $listItem.hasClass('active');

    if (isSubMenuOpen) {
      setTimeout(function () {
        $('.js-submenu-open.active').removeClass('active');
      }, 80);
    }
  });

  $listItem.each(function (index) {
    $(this).addClass('submenu-' + index);
  });

  $menuItem.on('click', '.js-submenu-tap', function () {
    if ($(window).width() >= window.screenSizes.mobile) {
      return;
    }

    const $subMenuTap = $(this);
    const index = $subMenuTap.attr('data-index');
    const $subMenuOpen = $('.js-submenu-open.submenu-' + index);

    $mainMenu.css('overflow', 'hidden');
    $subMenuOpen.addClass('active');
    $subMenuOpen.css({ left: '100%' }).animate({ left: 16 }, 350);
  });


  $menuClose.on('click', function (e) {
    e.preventDefault();

    const $subMenuOpen = $('.js-submenu-wrapper').find('.js-submenu-open');

    if ($(window).width() >= window.screenSizes.mobile) {
      return;
    }

    $mainMenu.css('overflow', 'scroll');
    $subMenuOpen.animate({ left: '100%' }, 350, function () {
      $subMenuOpen.removeClass('active');
    });
  });

  $takeChargeToggle.click(function () {
    $('.a-nav-search__takecharge-icon, .a-nav-search__takecharge-text').toggleClass('swapped');

    $(this).toggleClass('active');
  });

  function showPopoverLogin($popover) {
    $popover.popover({
      html: true,
      content: function () {
        const id = $(this).attr('data-popover-content');
        return $('#popover-content-' + id).html();
      }
    });

    $popover.on('shown.bs.popover', hoverHandler);
  }

  function hoverHandler() {
    if (isActive) {
      return;
    }

    const $popoverArrow = $('.popover .arrow');

    $('.js-login-wrapper:first-child').hover(function () {
      $popoverArrow.addClass('active-arrow');
    }, function () {
      $popoverArrow.removeClass('active-arrow');
    });
  }

  function hidePopoverLogin($popover) {
    $popover.off('shown.bs.popover', hoverHandler);
    $popover.popover('dispose');
  }

  function popoverLogin() {
    if ($(window).width() >= window.screenSizes.mobile) {
      showPopoverLogin($popoverLogin);
      hidePopoverLogin($popoverLoginMobile);
    } else {
      hidePopoverLogin($popoverLogin);
      showPopoverLogin($popoverLoginMobile);
    }
  }

  popoverLogin();

  $login.on('click', function () {
    let checkActive = $popoverLoginWrapper.first().hasClass('active');
    if (checkActive) {
      $('.popover .arrow').addClass('active-arrow');
    }
  });

  function showHideElementsNavSearch() {
    if (window.isNotDesktopScreen()) {
      $navSearchLinks.show();

      if (!$navbarSupportedContent.hasClass('navbar-collapse')) {
        $navbarSupportedContent.addClass('navbar-collapse');
      }
    } else {

      if (!$('#navbarSupportedContent .js-login').hasClass('d-lg-block')) {
        $('#navbarSupportedContent .js-login').addClass('d-lg-block');
      }
    }
  }

  showHideElementsNavSearch();

  $(document).on('click', function (e) {
    $('[data-toggle="popover"]').each(function () {
      if (!$(this).is(':visible')) {
        return;
      }

      if (!$(this).is(e.target) &&
        $(this).has(e.target).length === 0 &&
        $('.popover').has(e.target).length === 0 ||
        $(e.target).is('.js-login-wrapper')) {
        $(this).popover('hide');
      }
    });
  });

  $(window).on('resize scroll', function () {
    var x = window.isNotDesktopScreen() && window.pageYOffset > headerHeight;
    $navigationWrap.toggleClass('is-fixed', window.isNotDesktopScreen() && window.pageYOffset > 0);

    if ($(window).width() >= window.screenSizes.mobile) {
      $('body').removeClass('hidden-scroll');
    }

    showHideElementsNavSearch();
    popoverLogin();
  });

  const $header = $('.js-navigation-wrap');
  const $dummyHeader = $header.siblings('.js-navigation-dummy');
  const $miniNavTab = $('.mini-nav__wrapper');
  let headerHeight, navHeaderPos = 0;
  let lastScroll = 0;
  let isScrolled = false;
  let isDummySticky = false;

  function initSticky() {
    headerHeight = $header.height();
    $dummyHeader.height(headerHeight);
    if ($miniNavTab.length) {
      const $np = $miniNavTab.parent();
      navHeaderPos = $np.offset().top + $np.height() - $miniNavTab.height() - headerHeight;
    }
  }

  $dummyHeader.hide();
  initSticky();
  $(window).scroll(function () {
    const currentScroll = $(window).scrollTop();
    const scrollDirection = currentScroll < lastScroll;
    const windowWidth = $(window).width();
    isScrolled = currentScroll > 0;

    if (windowWidth <= 992) {
      if (isScrolled && !isDummySticky) {
        isDummySticky = true;
        $dummyHeader.show();
      }
      else if (!isScrolled && isDummySticky) {
        isDummySticky = false;
        $dummyHeader.hide();
      }
    }

    const shouldToggleHeader = isScrolled && (scrollDirection || headerHeight > currentScroll);
    const shouldToggleMiniNavTab = shouldToggleHeader && $miniNavTab.hasClass('sticky');

    if (windowWidth <= 767) {
      $header.toggleClass('sticky', shouldToggleHeader);
      $miniNavTab.toggleClass('add-top', shouldToggleMiniNavTab);
    }

    lastScroll = currentScroll;
  });

  $(window).on('resize', function () {
    initSticky();
    if ($(window).width() > 992) {
      $dummyHeader.hide();
    }
  });
});
