$(document).ready(function () {
  const discoverCardWrap = $('.discover__wrap');
  if(!discoverCardWrap.length) return;
  
  const discoverCard = $('.discover__card');

  discoverCard.each(function () {
    if ($(this).find('.js-discover-url').attr('href') === '') {
      $(this).find('.discover__content-bg').hide();
      $(this).find('.discover__content-icon').hide();
      $(this).find('.link').hide();
      $(this).find('.discover__content-text').addClass('p-0');
    }
  });

  $(window).on('resize', function () {
    discoverCardWrap.find('.js-matchHeight').matchHeight();
  });

  $(window).on('load', function () {
    discoverCardWrap.find('.js-matchHeight').matchHeight();
  });

  setTimeout(function () {
    discoverCardWrap.find('.js-matchHeight').matchHeight();
  }, 100);
});
