$(document).ready(function () {
  const activeClass = 'active';
  const interactiveCircleClass = '.js-interactive-circle';

  const $circleContainTemplate = $('#circle-contain-template');
  const $jsInterReferral = $('.js-interactive-referral');
  const $interactiveCircleDesktop = $('.js-interactive-circle-desktop');
  const $itemsCircle = $('.js-items-circle');

  if (!$jsInterReferral.length) {
    return;
  }

  function populateCircle() {
    const $circleTemplate = $($circleContainTemplate.html());

    $interactiveCircleDesktop.each(function () {
      const $newCircle = $circleTemplate.clone();

      $itemsCircle.append($newCircle);
    });
  }

  function activeCircle(index) {
    $(interactiveCircleClass).removeClass(activeClass);
    $(interactiveCircleClass).eq(index).addClass(activeClass);

    $interactiveCircleDesktop.removeClass(activeClass);
    $interactiveCircleDesktop.eq(index).addClass(activeClass);
  }

  function goToSlideByCircleEvent() {
    let index = $interactiveCircleDesktop.index(this);
    if (index < 0) {
      index = $(interactiveCircleClass).index(this);
    }

    $jsInterReferral.slick('slickGoTo', index);
  }

  populateCircle();
  activeCircle(0);

  $jsInterReferral.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    swipe: false,
    arrows: false,
    fade: true,
    cssEase: 'linear',
  });

  $jsInterReferral.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    activeCircle(nextSlide);
  });

  $(document).on('click', interactiveCircleClass, goToSlideByCircleEvent);
  $interactiveCircleDesktop.on('click', goToSlideByCircleEvent);
});
