$(document).ready(function () {
  const $fullscreenImages = $('.fullscreen-masthead');
  if (!$fullscreenImages.length) return;

  let currentActiveSticky = 0;
  $fullscreenImages.each(function (i, e) {
    let navbarCount = i + 1;
    const $fullscreenImage = $(e);
    const $miniNavbar = $fullscreenImage.find('.mini-nav__wrapper');
    if (!$miniNavbar.length) return;
    const miniNavHeight = $miniNavbar.find('.mini-nav').outerHeight();
    const $wrapper = $miniNavbar.find('.js-navbar-wrapper');
    const $navItem = $wrapper.find('.js-nav-item');
    const $mobileLabel = $miniNavbar.find('.navbar-brand.for-mobile');
    let mobileLabelText = $miniNavbar.find('.navbar-brand.for-mobile').text();
    let imageDesktop, imageMobile = 0;
    function initSize() {
      let isSticky = $miniNavbar.hasClass('sticky');
      if (isSticky) $miniNavbar.removeClass('sticky');

      imageDesktop = $wrapper.offset().top;
      imageMobile = $wrapper.offset().top;

      if (isSticky) $miniNavbar.addClass('sticky')
    }
    initSize();

    let stickyFlag = false;
    let persistStickyFlag = true;
    function updateStickyNavbar() {
      const scrollPos = $(window).scrollTop();
      const windowWidth = $(window).width();
      if ((windowWidth <= 767 && scrollPos >= imageMobile) || (windowWidth > 767 && scrollPos >= imageDesktop)) {
        if ($navbarCollapse.hasClass('show')) {
          if (persistStickyFlag) {
            $navbarToggler.click();
          }
        }
        if (currentActiveSticky < navbarCount) {
          currentActiveSticky = navbarCount;
        }

        if (currentActiveSticky == navbarCount) {
          if (!stickyFlag) {
            stickyFlag = true;
          }
        }
        else if (stickyFlag) {
          stickyFlag = false;
        }
      } else if ((windowWidth <= 767 && scrollPos < imageMobile - ($miniNavbar.hasClass('add-top') ? $wrapper.offset().top - scrollPos : 0)) || (windowWidth > 767)) {
        if (stickyFlag && currentActiveSticky == navbarCount) {
          currentActiveSticky = 0;
          stickyFlag = false;
        }
      }
      $miniNavbar.toggleClass('sticky', stickyFlag);

      $miniNavbar.css('max-height', miniNavHeight);

      const $scrollIndicator = $('.scroll-indicator');
      const scrollPercentage = (($(window).scrollTop() + $(window).height()) / $(document).height()) * 100;
      $scrollIndicator.css('width', scrollPercentage + '%');
    }

    const navID = 'mini-nav-' + navbarCount;
    const $navbarCollapse = $fullscreenImage.find('.navbar-collapse');
    $navbarCollapse.attr('id', navID);
    const $navbarToggler = $fullscreenImage.find('.navbar-toggler');
    $navbarToggler.attr('data-target', '#' + navID);

    const $navbarBrand = $fullscreenImage.find('.navbar-brand');
    const $blurOverlay = $fullscreenImage.find('.blur-overlay');
    const $navLink = $fullscreenImage.find('.nav-item.nav-link');

    $navbarCollapse.on('show.bs.collapse', function () {
      $blurOverlay.show();
      $navbarBrand.addClass('dimmed');

      persistStickyFlag = false;
      $('html, body').animate({
        scrollTop: $wrapper.offset().top
      }, 500);
      setTimeout(function(){
        persistStickyFlag = true;
      },600)
    });

    $navbarCollapse.on('hide.bs.collapse', function () {
      $blurOverlay.hide();
      $navbarBrand.removeClass('dimmed');
    });

    $navLink.on('click', function () {
      $navbarCollapse.collapse('hide');
    });

    function setVideoBgHeight() {
      const mainNavHeight = $('.a-nav-search').find('.a-nav-search__wrap').outerHeight();
      const bgCont = $('.hero-video');
      const totalBgCont = $(window).height() - mainNavHeight;

      bgCont.css('height', totalBgCont);
    }

    setVideoBgHeight();

    const $hiddenElements = $fullscreenImage.find('.js-hidden-elements-container');
    const $elements = $wrapper.find('.js-nav-item');
    const $ellipsis = $wrapper.find('.js-ellipsis');
    let isExpanded = false;

    function hideExcessElements() {
      const miniNavOrange = $wrapper.parent().hasClass('mini-nav__anchor');
      const miniNavBlue = $wrapper.parent().hasClass('mini-nav__tab');

      $elements.show();
      $ellipsis.hide();
      $hiddenElements.hide();
      $ellipsis.removeClass('active');
      $wrapper.css({ 'max-width': '', 'width': '' });
      initSize();

      const wrapperWidth = $wrapper.outerWidth(true);
      const navbrandWidth = $wrapper.find('.navbar-brand.for-desktop').outerWidth();
      const ellipsisWidth = $wrapper.find('.mini-nav__ellipsis').outerWidth();
      const backToTopWidth = $wrapper.find('.back-to-top__wrapper').outerWidth();
      const screenWidth = window.innerWidth;
      $wrapper.css({ 'max-width': 'fit-content', 'width': 'auto' });

      let totalWidth = 0;
      let finalTotalWidthOrange = 0;
      let finalTotalWidthBlue = 0;

      $elements.each(function (index) {
        const $element = $(this);
        if ($element.parent().hasClass("js-hidden-elements-container")) {
          return true;
        }
        totalWidth += $element.outerWidth(true);
        finalTotalWidthOrange = totalWidth + navbrandWidth + ellipsisWidth + backToTopWidth;
        finalTotalWidthBlue = totalWidth + ellipsisWidth + backToTopWidth;

        if (screenWidth >= 768 && miniNavOrange) {
          if (finalTotalWidthOrange > wrapperWidth || index >= 5) {
            $element.hide();
            $ellipsis.css('display', 'flex');
            if ($element.hasClass('active')) {
              $ellipsis.addClass('active');
            }
          }
        } else if (miniNavBlue && screenWidth >= 768) {
          if (finalTotalWidthBlue > wrapperWidth || index >= 5) {
            $element.hide();
            $ellipsis.css('display', 'flex');
            if ($element.hasClass('active')) {
              $ellipsis.addClass('active');
            }
          }
        }
        if (isExpanded) {
          hiddenElement(true);
        }
      });
    }

    hideExcessElements();

    function updateHiddenElements() {
      isExpanded = !isExpanded;
      hiddenElement(isExpanded);
    }

    function hiddenElement(show) {
      if (show) {
        $hiddenElements.empty();
        $elements.each(function () {
          if ($(this).css('display') === 'none') {
            $hiddenElements.append($(this).clone());
          } else {
            $hiddenElements.find('.js-nav-item').remove();
          }
        });
        $hiddenElements.show();
      } else {
        $hiddenElements.hide();
      }
    }

    let navs = [];
    function initNavigationAnchor() {
      $navItem.each(function () {
        const $e = $(this);
        const targetId = $e.attr('data-id');
        if (targetId == undefined) return;

        const $targetSection = $(targetId);
        if (!$targetSection.length) return;

        navs.push({
          navElement: $e,
          targetElemet: $targetSection,
          startPoint: 0,
          endPoint: 0,
          active: false,
        })
      });
      updateNavTargerSize();
      navs.sort(function (x) {
        return x.startPoint;
      })
      navs.forEach(function (current) {
        if (current.navElement) {
          current.navElement.on('click', function (e) {
            e.preventDefault();
            var windowTop = $(window).scrollTop();
            if (current.startPoint > $(window).scrollTop()) {
              $('html, body').animate({ scrollTop: windowTop - 1 }, 'fast');
            }
            setTimeout(() => {
              $('html, body').animate({ scrollTop: current.startPoint - miniNavHeight }, 1000);
            }, 100);
          });
        }
      })
    };
    initNavigationAnchor();

    function updateNavTargerSize() {
      navs.forEach(function (current, index, collection) {
        current.startPoint = current.targetElemet.offset().top;
        let nextIndex = index + 1;
        if (nextIndex < collection.length) {
          current.endPoint = collection[nextIndex].targetElemet.offset().top;
        } else {
          current.endPoint = $('footer').offset().top;
        }
        current.active = false;
      });
    }
    updateNavTargerSize();

    function handleScroll() {
      if (!navs.length) return;
      let flag = false;
      navs.forEach(function (current) {
        if (flag) {
          current.active = false;
        }
        else if (isTargetInViewport(current)) {
          flag = current.active = true;
        } else if (current.active) {
          current.active = false;
        }
        current.navElement.toggleClass('active', current.active);
      });
      if ($(window).width() <= 767) {
        const $act = !$navItem.filter(function () {
          return $(this).hasClass('active');
        });
        if ($act.length) {
          $mobileLabel.text($act.first().find('span').text());
        }
        else {
          $mobileLabel.text(mobileLabelText);
        }
      }
    }

    function isTargetInViewport(nav) {
      const windowTop = $(window).scrollTop() + (stickyFlag ? miniNavHeight : 0);
      const windowsHeight = $(window).height();
      const windowBottom = windowTop + windowsHeight;
      return (nav.endPoint < windowTop) || (nav.startPoint > windowBottom)
        ? 0 : (
          (windowTop < nav.startPoint && nav.endPoint < windowBottom)
          || (nav.startPoint < windowTop && windowBottom < nav.endPoint)
          || (nav.startPoint < windowBottom && windowBottom < nav.endPoint && windowBottom - nav.startPoint > windowsHeight * 0.9)
          || (nav.startPoint < windowTop && windowTop < nav.endPoint && nav.endPoint - windowTop > windowsHeight * 0.1));
    }

    function scrollToTop() {
      $('html, body').animate({ scrollTop: 0 }, 'slow');
    }

    $(window).on('load', function () {
      initSize();
      updateStickyNavbar();
    });

    $(window).on('resize', function () {
      initSize();
      setVideoBgHeight();
      hideExcessElements();
      updateStickyNavbar();
      updateNavTargerSize();
    });

    $(window).on('scroll', function () {
      updateStickyNavbar();
      handleScroll();
    });

    $ellipsis.on('click', updateHiddenElements);
    $('.back-to-top').on('click', scrollToTop);
  });
});
