$(document).ready(function () {
  const $slidePricePlans= $('.js-price-plans');
  const $sliderPricePlanFilter = $('.js-price-plan-filter');
  const $sliderLifePlans = $('.js-slick-life-plan');
  const $slideBusinessPlans= $('.js-business-plans');
  const $sectionBusinessPlans = $('.js-section-business-plans');
  const $sliderOemPlans = $('.js-slick-oem');
  const $slideRewards = $('.js-rewards');
  const $lifePlan = $('.js-life-plan');
  const $containerCard = $('.js-card-container');

  const itemSameHeight = '.js-item-same-height';
  const itemSameHeightQuantity = '.js-item-quantity-same-height';
  const itemTopLine = '.js-item-top-line';
  const sameHeightTitle = '.title-custom-font';
  const sameHeightListText = '.price-plan-card__list-text';
  const sameHeightParagraph = '.price-plan-card__paragraph';
  const sameHeightBoxStatic = '.price-plan-card__box-static';
  const sameSubtitlePriceCard = '.price-plan-card__subtitle';

  $slidePricePlans.slick({
    infinite: false,
    speed: 300,
    mobileFirst: true,
    centerMode: false,
    centerPadding: '10%',
    variableWidth: true,
    arrows: false,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: 'unslick'
      },
    ]
  }).on('setPosition', function() {
    setHeightForCard($containerCard, itemTopLine);
    setHeightForCard($containerCard, sameSubtitlePriceCard);
  });

  $sliderPricePlanFilter.slick({
    infinite: false,
    speed: 300,
    mobileFirst: true,
    centerMode: true,
    centerPadding: '15%',
    variableWidth: true,
    arrows: false,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: 'unslick'
      },
    ]
  }).on('setPosition', function() {
    setHeightForCard($sliderPricePlanFilter, sameHeightListText);
  });

  $sliderLifePlans.slick({
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    centerMode: false,
    centerPadding: '10%',
    variableWidth: true,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 991,
        settings: 'unslick'
      }
    ]
  }).on('setPosition', function() {
    if ($sliderLifePlans.is(':hidden')) return;

    setHeightForCard($sliderLifePlans);
    setHeightForCard($sliderLifePlans, sameHeightListText);
  });

  $slideBusinessPlans.slick({
    infinite: false,
    speed: 300,
    mobileFirst: true,
    centerMode: true,
    centerPadding: '15%',
    arrows: false,
    dots: true,
    variableWidth: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: 'unslick'
      },
    ]
  }).on('setPosition', function() {
    setHeightForCard($containerCard, itemTopLine);
    setHeightForCard($containerCard, sameSubtitlePriceCard);
  });

  $sliderOemPlans.slick({
    infinite: false,
    speed: 300,
    mobileFirst: true,
    centerMode: true,
    centerPadding: '15%',
    arrows: false,
    dots: true,
    variableWidth: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: 'unslick'
      },
    ]
  }).on('setPosition', function() {
    setHeightForCard($containerCard, itemTopLine);
    setHeightForCard($containerCard, sameSubtitlePriceCard);
  });

  $slideRewards.slick({
    infinite: false,
    speed: 300,
    mobileFirst: true,
    centerMode: true,
    centerPadding: '15%',
    arrows: false,
    dots: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 767,
        settings: 'unslick',
      },
    ],
  }).on('setPosition', function () {
    setHeightForCard($containerCard, itemTopLine);
    setHeightForCard($containerCard, sameSubtitlePriceCard);

    const currentSlide = $slideRewards.slick('slickCurrentSlide') + 1;
    const totalSlides = $slideRewards.slick('getSlick').slideCount;

    const customPagination = `<div class="rewards__pagination"><span>${currentSlide}</span>/${totalSlides}</div>`;

    $('.rewards__pagination').remove();

    $slideRewards.append(customPagination);
  });

  function resetSlickCarousel() {
    $slideRewards.slick('slickGoTo', 0);
  }

  function setHeightForCard(sections, itemSameHeight) {
    if (!sections || !sections.length) return;

    $(sections).each(function(idx, section) {
      const cardRatio = $(section).data('card-ratio');
      const $cards = $(section).find(itemSameHeight);
      const $sameHeightTitleRewards = $(section).find(itemSameHeight);

      makeCardsSameHeight($sameHeightTitleRewards, parseFloat(cardRatio));
      makeCardsSameHeight($cards, parseFloat(cardRatio));
    });
  }

  function makeCardsSameHeight($cards, cardRatio) {
    if (!$cards.length) {
      return;
    }

    $cards.height('auto');

    let maxHeight = $cards.height();
    let maxWidth = $cards.width();
    $cards.each(function(index, el) {
      const heightCurrent = $(el).height();
      if (maxHeight < heightCurrent) {
        maxHeight = heightCurrent;
      }

      const widthCurrent = $(el).width();
      if (maxWidth < widthCurrent) {
        maxWidth = widthCurrent;
      }
    });

    if (cardRatio) {
      var maxHeightRatio = maxWidth * cardRatio;
      if (maxHeight < maxHeightRatio) {
        maxHeight = maxHeightRatio;
      }
    }

    $cards.height(maxHeight);
  }

  setHeightForCard($slideRewards, sameHeightBoxStatic);
  setHeightForCard($sliderOemPlans, sameHeightListText);
  setHeightForCard($sectionBusinessPlans, sameHeightListText);
  setHeightForCard($sectionBusinessPlans, sameHeightParagraph);
  setHeightForCard($sliderLifePlans, sameHeightListText);
  setHeightForCard($lifePlan, itemSameHeight);
  setHeightForCard($lifePlan, itemSameHeightQuantity);
  setHeightForCard($containerCard, itemTopLine);
  setHeightForCard($containerCard, itemSameHeight);
  setHeightForCard($containerCard, itemSameHeightQuantity);
  setHeightForCard($slideRewards, sameHeightTitle);
  setPositionBoxStaticReward();

  function setPositionBoxStaticReward() {
    $slideRewards.find('.price-plan-card__box-static').addClass('price-plan-card__box-static--relative');
    const heightPromotionValid = $slideRewards.find('.price-plan-card__promotion-valid').outerHeight() + 10;
    $slideRewards.find('.price-plan-card__box-static--relative img').css('bottom', heightPromotionValid);
    const heightImage = $slideRewards.find('.price-plan-card__box-static--relative img').outerHeight() + 15;

    let staticHeight = heightPromotionValid;

    if (heightImage){
      staticHeight += heightImage;
    }
    $slideRewards.find('.price-plan-card__box-static').css('height', staticHeight);
  }

  function initializeAllSlide() {
    $sliderLifePlans.slick('resize');
    $sliderPricePlanFilter.slick('resize');
    $slideBusinessPlans.slick('resize');
    $slidePricePlans.slick('resize');
    $sliderOemPlans.slick('resize');
    $slideRewards.slick('resize');
  }

  initializeAllSlide();

  $(window).on('resize', function () {
    setHeightForCard($sliderPricePlanFilter, sameHeightListText);
    setHeightForCard($slideRewards, sameHeightBoxStatic);
    setHeightForCard($sliderOemPlans, sameHeightListText);
    setHeightForCard($sectionBusinessPlans, sameHeightListText);
    setHeightForCard($sectionBusinessPlans, sameHeightParagraph);
    setHeightForCard($sliderLifePlans, sameHeightListText);
    setHeightForCard($lifePlan, itemSameHeight);
    setHeightForCard($lifePlan, itemSameHeightQuantity);
    setHeightForCard($containerCard, itemSameHeightQuantity);
    setHeightForCard($containerCard, itemTopLine);
    setHeightForCard($containerCard, itemSameHeight);
    setHeightForCard($slideRewards, sameHeightTitle);
    setHeightForCard($containerCard, sameSubtitlePriceCard);
    setPositionBoxStaticReward();
    initializeAllSlide();
    setHeightForCard();
  });

  window.resetSlickCarousel = resetSlickCarousel;
  window.setHeightForCard = setHeightForCard;
  window.setPositionBoxStaticReward = setPositionBoxStaticReward;
});
