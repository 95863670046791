$(document).ready(function () {
  const isHidden = 'is-hidden';
  const $notification = $('.js-notification');
  const $addCookie = $('.js-item-close');

  const hiddenNotification = () => {
    $notification.each( function (index, item) {
      const messageID = $(item).attr('data-message-id');
      if ($.cookie(messageID)){
        $(item).addClass(isHidden);
      }
    });
  };
  $addCookie.on('click', function () {
    const parent = $(this).closest($notification);
    parent.addClass(isHidden);
    const expires_days = parent.attr('data-cookie-life');
    if(expires_days){
      var expire = new Date();
      expire.setTime(expire.getTime() + (1000 * 60 * 60 * 24 * expires_days)); 
      $.cookie((parent.attr('data-message-id')), true, { expires: expire } );
    }else{
      $.cookie((parent.attr('data-message-id')), true, { expires: null } );
    }
   
  });
  hiddenNotification();
});
