(function(global) {
  global.ErrorHandler = {
    get errors() {
      return window.errors;
    },
    getErrorByKey: function(key) {
      let message = this.errors[key];
      if (!message) {
        message = this.errors['UNKNOWN_ERROR'];
      }

      return message;
    },
  };

  global.debounce = (func, wait, immediate) => {
    let timeout;
    return function() {
      const context = this,
        args = arguments;

      const later = function() {
        timeout = null;
        if ( !immediate ) {
          func.apply(context, args);
        }
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait || 200);
      if (callNow) {
        func.apply(context, args);
      }
    };
  };

  global.copyClipboard = ($copyInput) => {
    const copyInput = $copyInput.get(0);

    copyInput.focus();
    copyInput.setSelectionRange(0, 99999999);

    try {
      document.execCommand('copy');
    } catch (err) {
      console.error(err);
    }

    copyInput.blur();
  };

  global.callMethodInIframe = (iframeId, functionName, params) => {
    const iframeElement = document.getElementById(iframeId);
    if (!iframeElement || !iframeElement.contentWindow) {
      return null;
    }

    return iframeElement.contentWindow[functionName](params);
  };

  global.getObjectInIframe = (iframeId, objectName) => {
    const iframeElement = document.getElementById(iframeId);
    if (!iframeElement || !iframeElement.contentWindow) {
      return null;
    }

    return iframeElement.contentWindow[objectName];
  };

  global.isMobileScreen = () => {
    return window.innerWidth < 768 || (window.innerWidth <= 896 && window.innerHeight <= 414);
  };

  global.isNotDesktopScreen = () => {
    return window.innerWidth < 992;
  };

  global.isMobileDevice = () => {
    return navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i);
  };

  global.isOnIEBrowser = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');

    if (msie > 0) {
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
  };

  global.getUrlParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    // eslint-disable-next-line no-useless-escape
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  global.scrollToElement = (el) => {
    const scrollElement = el || $(window.location.hash);

    if (scrollElement && scrollElement.length) {
      setTimeout(function () {
        const container = $('html, body');

        const top = scrollElement.offset().top;

        container.animate({
          scrollTop: top
        }, 'slow');
      }, 1);
    }
  };

  global.screenSizes = {
    mobile: 992,
  };
})(window);
