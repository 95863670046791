$(document).ready(function () {
  const $rootRewards = $('.js-section-rewards');
  if (!$rootRewards.length) return;

  $rootRewards.each(function () {
    const $rootReward = $(this);
    const $rewardsFilter = $rootReward.find('.js-rewards-filter');
    const $rewardsFilterIcon = $rootReward.find('.js-rewards-filter > i');
    const $rewardsCategoriesWrapper = $rootReward.find('.rewards-category__list');
    const $rewardsCategories = $rootReward.find('.rewards-category__items > li');
    const $rewardsSlider = $rootReward.find('.js-rewards');
    const $rewards = $rewardsSlider.find('.rewards__item');
    const $visibleClass = 'visible';
    const $rewardsCategoriesComponent = $rootReward.find('.rewards-category');
    const $selectedCategory = $rewardsCategoriesComponent.find('.rewards-category__selected span');
    const $selectedCategoryWrap = $rewardsCategoriesComponent.find('.rewards-category__selected');
    const $ul = $rewardsCategoriesComponent.find('.rewards-category__items');
    const hiddenContent = 'hidden-content';
    const cardPerLoad = $rootReward.data('cards') < 3 || $rootReward.data('cards') == undefined ? $rewards.length : $rootReward.data('cards')
    let numberOfCards = cardPerLoad;
    if (!$rewardsSlider.length) return;

    const $button = $rewardsSlider.parent('.rewards').siblings('.rewards__cta').find('button');
    if ($button.length) {
      $button.on('click', function (event) {
        event.preventDefault();
        numberOfCards += cardPerLoad;
        updateNumberOfContent();
        categorySelectedOnload();
      });
      updateNumberOfContent();
    }

    function updateNumberOfContent() {
      $rewards.each(function (x) {
        var $reward = $(this);
        if (x < numberOfCards || !isDesktop()) {
          if ($reward.hasClass(hiddenContent)) {
            $reward.removeClass(hiddenContent);
          }
        } else {
          if (!$reward.hasClass(hiddenContent)) {
            $reward.addClass(hiddenContent);
          }
        }
      });
      const selectedCategory = $ul.find('.active').data('category');
      showHideRewards(selectedCategory);
      if (numberOfCards > $rewards.length) $button.attr('disabled', '');
    }

    const $rewardsCat = global.getParameterByName('category');
    setTimeout(() => {
      categorySelectedOnload();
    }, 100);

    getCategoriesList();

    function getCategoriesList() {
      let cardArr = [''];
      $rewards.each(function (index) {
        var $list = $(this).data('categories').split(',');
        if ($list) {
          cardArr = cardArr.concat($list.filter((item) => cardArr.indexOf(item) < 0));
        }
      });

      if (cardArr.length > 1) {
        $rewardsCategories.each(function (index) {
          if ($.inArray($(this).data('category'), cardArr) === -1) {
            $(this).hide();
          }
        });
      } else {
        $rewardsCategoriesComponent.hide();
      }
    }

    function categorySelectedOnload() {
      //On Page Load
      if ($rewardsCat) {
        clearActiveCategory();
        $('[data-category="' + $rewardsCat + '"]').addClass('active');
        showHideRewards($rewardsCat);
        showMobileFilter();
        slickRefresh();
        setHeight();
      }
    }

    function getSelectedCategory() {
      return localStorage.getItem('selectedCategory') || 'All Rewards';
    }

    function setSelectedCategory(category) {
      localStorage.setItem('selectedCategory', category);
    }

    function toggleListItems(containerSelector) {
      var $container = $(containerSelector);
      var $ul = $container.find('.rewards-category__items');

      if ($rewardsCategories.length > 5 && !isDesktop()) {
        $ul.hide();
        $ul.addClass('rewards-category__dropdown');
        $selectedCategoryWrap.show();
      } else if ($rewardsCategories.length < 5 || isDesktop) {
        $ul.show();
        $selectedCategoryWrap.hide();
        $ul.removeClass('rewards-category__dropdown');
      }
    }

    $rewardsFilter.on('click', (event) => {
      if (!isDesktop()) {
        if ($rewardsFilterIcon.length > 0) {
          $rewardsFilterIcon.toggleClass('icon-plus icon-minus');
        }
        $rewardsCategoriesWrapper.slideToggle('600');
      }
    });

    $rewardsCategories.on('click', function (event) {
      event.preventDefault();
      var selectedCategory = $(this).text();
      clearActiveCategory();
      $(event.target).addClass('active');
      var $cat = $(event.target).data('category');
      showHideRewards($cat);
      urlRewrite($cat);
      slickRefresh();
      setHeight();
      $rewardsCat = $cat;
      $selectedCategory.text(getSelectedCategory());
      window.resetSlickCarousel();

      if ($rewardsCategories.length > 5 && $(window).width() < 768) {
        $selectedCategory.text(selectedCategory);
        setSelectedCategory(selectedCategory);
        $(this).parent().siblings('.rewards-category__selected').removeClass('open');
        $ul.slideUp();
      }
    });

    $selectedCategoryWrap.on('click', function (event) {
      event.preventDefault();
      $(this).toggleClass('open');
      $ul.slideToggle();
    });

    function showMobileFilter() {
      if (!isDesktop()) {
        if (!$rewardsFilterIcon.hasClass('icon-minus')) {
          $rewardsFilterIcon.removeClass('icon-plus');
          $rewardsFilterIcon.addClass('icon-minus');
        }
      }
      $rewardsCategoriesWrapper.slideDown('600');
    }

    function urlRewrite($cat) {
      //Rewrite URL
      var oldURL = window.location.href.split('?')[0];
      var param = $cat ? '?category=' + $cat : '';

      if (history.pushState) {
        var newUrl = oldURL + param;
        window.history.pushState({ path: newUrl }, '', newUrl);
      }
    }

    function clearActiveCategory() {
      $rewardsCategories.each(function (index) {
        $(this).removeClass('active');
      });
    }

    function showHideRewards(category) {
      $rewards.each(function (index) {
        var $el = $(this);

        $el.removeClass('hidden');
        $el.removeClass($visibleClass);
        if ($el.hasClass(hiddenContent)) {
          $el.addClass('hidden');
        }
        if (category) {
          if ($el.hasClass(hiddenContent)) {
            $el.addClass('hidden');
          } else if ($.inArray(category, $el.data('categories').split(',')) == -1) {
            $el.addClass('hidden');
          } else {
            $el.addClass($visibleClass);
          }
        } else if ($el.hasClass(hiddenContent)) {
          $el.addClass('hidden');
        } else {
          $el.addClass($visibleClass);
        }
      });
    }

    function isDesktop() {
      return window.innerWidth >= 768;
    }

    function slickRefresh() {
      $rewardsSlider.slick('slickUnfilter');
      $rewardsSlider.slick('slickFilter', `.${$visibleClass}`);
    }

    function setHeight() {
      window.configColumnLayoutCard('.js-section-rewards');
      window.setHeightForCard($rewardsSlider, '.price-plan-card__box-static');
      window.setHeightForCard('.js-card-container', '.js-item-quantity-same-height');
      window.setHeightForCard('.js-card-container', '.js-item-top-line');
      window.setHeightForCard('.js-card-container', '.js-item-same-height');
      window.setPositionBoxStaticReward();
      window.setHeightForCard();
    }

    toggleListItems('.rewards-category__list');

    $(window).resize(function () {

      if ($rewardsCat) {
        showMobileFilter();
        slickRefresh();
      }

      if (isDesktop()) {
        $rewardsCategoriesWrapper.removeAttr('style');
      }

      setHeight();
      toggleListItems('.rewards-category__list');
      updateNumberOfContent();
    });
  });
});

