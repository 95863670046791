$(document).ready(function () {
  const sectionLifePlan = '.js-section-price-filter';
  const isCheckedClass = 'is-checked';
  const isHiddenModal = 'is-hidden-modal';
  const visibleClass = 'js-visible-item';
  const pricePlanFilterInput = '.js-price-plan-filter-input';
  const pricePlanFilterItem = '.js-price-plan-filter-item';

  const $modalItemFilter = $('#modalMultipleFilter');
  const $modalItemFilter1 = $('#modalSingleFilter');

  function handleCardFilter(tabSection, cardSection, tabTtem, isMultiFilter) {
    const maxDisplayedItemCount = tabTtem;
    const $tabSection = $(tabSection);
    const $pricePlanFilterContainer = $tabSection.find($('.js-price-plan-filter-container'));
    const $pricePlanFilterItem = $tabSection.find($(pricePlanFilterItem));
    const $pricePlanFilterInput = $tabSection.find($(pricePlanFilterInput));

    const $modalPricePlanFilterItem = $tabSection.find($('.js-modal-price-plan-filter-item'));
    const $modal = $tabSection.find('.js-modal-price-filter');
    const $modalInputFilters = $tabSection.find('.js-price-plan-filter-input-modal');

    const $pricePlanMoreFilterItem = $tabSection.find($('.js-price-plan-more-filter-item'));
    const $pricePlanFilterText = $tabSection.find($('.js-price-plan-filter-text'));
    const $planFilterTitle = $tabSection.find('.js-plan-filter-title');
    const $saveFilter = $tabSection.find('.js-btn-save-select');
    const $clearAllItemChosen = $tabSection.find('.js-clear-all-item-chosen');
    const planFilterTitleDesktop = $planFilterTitle.data('target-title-desktop');
    const planFilterTitleMobile = $planFilterTitle.data('target-title-mobile');

    const $slider = $(cardSection).find($('.js-price-plan-filter'));
    const $sliderItems = $slider.find('.js-card');

    function activeFilter() {
      if (isDesktop()) {
        $sliderItems.hide();
        $slider.find(`.${visibleClass}`).show();
      } else {
        $sliderItems.show();
        $slider.slick('slickUnfilter');
        $slider.slick('slickFilter', `.${visibleClass}`);
      }
    }

    function changeFilterTitle() {
      if (isDesktop()) {
        $planFilterTitle.html(planFilterTitleDesktop);
      } else {
        $planFilterTitle.html(planFilterTitleMobile);
      }
    }

    updateItemVisible();

    function updateItemVisible() {
      if (!isDesktop()) {
        $pricePlanFilterItem.css('display', '');
        $modalPricePlanFilterItem.css('display', '').removeClass(isHiddenModal);
        return;
      }

      const widthItemsContainer = $pricePlanFilterContainer.width();
      const widthPricePlanFilterText = $pricePlanFilterText.outerWidth(true);
      const numberOfPricePlanItem = $pricePlanFilterItem.length;
      const menuItemAvailableWidth = widthItemsContainer - widthPricePlanFilterText;

      let pricePlanItemsWidth = 0;
      for (let i = 0; i < maxDisplayedItemCount && i < numberOfPricePlanItem; i++) {
        const $pricePlanItem = $($pricePlanFilterItem[i]);

        pricePlanItemsWidth += $pricePlanItem.outerWidth(true);
        const pricePlanItemIndex = 'tab-plan-duration';

        if (pricePlanItemsWidth >  menuItemAvailableWidth) {
          $pricePlanFilterItem.css('display', 'none');
          $($pricePlanFilterItem[0]).css('display', '');
          $pricePlanMoreFilterItem.css('display', 'inline-block');

          $modalPricePlanFilterItem.css('display', '').removeClass(isHiddenModal);
          $(`.js-modal-price-plan-filter-item[data-tab-active='${pricePlanItemIndex}']`).css('display', 'none').addClass(isHiddenModal);
        } else {
          $pricePlanItem.css('display', '');
          $pricePlanMoreFilterItem.css('display', '');
          $modalPricePlanFilterItem.css('display', 'none').addClass(isHiddenModal);
        }
      }
    }

    function clearAllItemChosen() {
      const $filterGroups = $modal.find('.js-modal-price-plan-filter-item:visible');
      const $filterInputs = $filterGroups.find('.js-price-plan-filter-input-modal');
      const $modalDropdownItems = $filterInputs.closest('.js-dropdown-item');

      $filterInputs.prop('checked', false);
      updateDropdown($modalDropdownItems, false);
    }

    function handleFilterSingleMenu($this) {
      const targetTextElement = $this.data('target-text');
      const activeElements = $this.data('active-elements');
      const $buttonTitle = $this.closest('.js-price-plan-filter-item').find('.js-price-plan-button');
      const $inputSelected = $tabSection.find($(`[data-active-elements='${activeElements}']`));
      const $dropdownItem = $inputSelected.closest('.js-dropdown-item');

      $buttonTitle.html(targetTextElement);
      $inputSelected.prop('checked', true);
      $dropdownItem.addClass(isCheckedClass);
      const $otherDropItems = $dropdownItem.siblings();
      $otherDropItems.removeClass(isCheckedClass).find('input').prop('checked', false);

      applySingleFilters();
    }

    function handleFilterMultipleMenu($this) {
      const isSelectAll = $this.data('select-all');
      const activeElements = $this.data('active-elements');

      const $buttonTitle = $this.closest('.js-price-plan-filter-item').find('.js-price-plan-button');
      const buttonTitle = $buttonTitle.data('button-text');
      const $inputSelected = $tabSection.find($(`[data-active-elements='${activeElements}']`));
      const $dropdownItem = $inputSelected.closest('.js-dropdown-item');

      $dropdownItem.toggleClass(isCheckedClass);
      $dropdownItem.hasClass(isCheckedClass) ? $inputSelected.prop('checked', true) : $inputSelected.prop('checked', false);

      const $root = $this.closest('.js-dropdown-menu');
      if (isSelectAll) {
        $root.find('.js-item-select').prop('checked', false);
        $root.find('.js-dropdown-item').removeClass('is-checked');
      }

      const $filterSelected = $root.find('.js-item-select:checked');

      if ($filterSelected.length === $root.find('.js-item-select').length) {
        $root.find('[data-select-all=true]').prop('checked', true);
        $root.find('[data-select-all=true]').closest('.js-dropdown-item').addClass('is-checked');
      } else {
        $root.find('[data-select-all=true]').prop('checked', false);
        $root.find('[data-select-all=true]').closest('.js-dropdown-item').removeClass('is-checked');
      }

      if ($filterSelected.length === 1) {
        $buttonTitle.html($filterSelected.data('target-text'));
      } else {
        $buttonTitle.html(buttonTitle);
      }

      applyMultiFilters();
    }

    $pricePlanFilterInput.on('click', function() {
      var $this = $(this);

      const isPricePlanFilterSingle = !isMultiFilter;

      if ( isPricePlanFilterSingle ) {
        handleFilterSingleMenu($this);
      } else {
        handleFilterMultipleMenu($this);
      }

      window.setHeightForCard(sectionLifePlan, '.js-item-same-height');
      window.setHeightForCard(sectionLifePlan, '.price-plan-card__list-text');
      window.setHeightForCard(sectionLifePlan, '.js-item-top-line');
    });

    $clearAllItemChosen.on('click', function() {
      clearAllItemChosen();
    });

    $saveFilter.on('click', function() {
      $modal.data('saving', true);
    });

    changeFilterTitle();

    $(window).resize(function() {
      if (!isMultiFilter) {
        applySingleFilters();
        invalidShowModal($modalItemFilter, tabTtem);
      } else {
        applyMultiFilters();
        invalidShowModal($modalItemFilter1, tabTtem);
      }

      changeFilterTitle();
      updateItemVisible();
    });

    function applySingleFilters() {
      const $selectedFilters = $tabSection.find('.js-price-plan-filter-input:checked');

      const filteredTypes = {};

      $selectedFilters.each(function(index, filter) {
        const $filter = $(filter);
        const isSelectAll = $filter.data('select-all');
        filteredTypes[$filter.data('filter-type')] = !isSelectAll ? $filter.data('active-elements') : 'all';
      });

      let $visibleItems = $sliderItems;

      Object.keys(filteredTypes).forEach(function(key) {
        const value = filteredTypes[key];
        const $selectedItems  = $sliderItems.filter(function(index, item) {
          return  $(item).data(key) === value;
        });

        if (value !== 'all') {
          $visibleItems = $visibleItems.filter(function(index, item) {
            return $selectedItems.filter(item).length;
          });
        }
      });

      $sliderItems.removeClass(visibleClass);
      $visibleItems.addClass(visibleClass);

      activeFilter();
    }

    function applyMultiFilters() {
      const $selectedFilters = $tabSection.find('.js-price-plan-filter-input.js-item-select:checked');
      const filteredTypes = {};

      $selectedFilters.each(function(index, filter) {
        const $filter = $(filter);
        if (!filteredTypes[$filter.data('filter-type')]) {
          filteredTypes[$filter.data('filter-type')] = [];
        }
        const filteredValues = filteredTypes[$filter.data('filter-type')];

        filteredValues.push($filter.data('active-elements'));
      });

      let $visibleItems = $sliderItems;

      Object.keys(filteredTypes).forEach(function(key) {
        const values = filteredTypes[key];
        let $subFilteredItems = null;
        values.forEach(function(value) {
          const $selectedItems = $sliderItems.filter(function(index, item) {
            return  $(item).data(key) === value;
          });
          if (!$subFilteredItems) {
            $subFilteredItems = $selectedItems;
          } else {
            $subFilteredItems = $subFilteredItems.add($selectedItems);
          }
        });

        $visibleItems = $visibleItems.filter(function(index, item) {
          return $subFilteredItems.filter(item).length;
        });
      });

      $sliderItems.removeClass(visibleClass);
      $visibleItems.addClass(visibleClass);

      activeFilter();
    }

    $modalInputFilters.on('click', function() {
      const $this = $(this);
      const $dropdownItem = $this.closest('.js-dropdown-item');

      if (isMultiFilter) {
        $dropdownItem.toggleClass(isCheckedClass);
        $this.prop('checked', $dropdownItem.hasClass(isCheckedClass));

      } else {
        $dropdownItem.addClass(isCheckedClass);
        $this.prop('checked', true);
        const $selectedFilterGroup = $this.closest('.js-modal-price-plan-filter-item');
        const $otherFilters = $selectedFilterGroup.find('.js-price-plan-filter-input-modal').not($this);

        $otherFilters.prop('checked', false);
        const $otherDropdownItems = $selectedFilterGroup.find('.js-dropdown-item').not($dropdownItem);
        $otherDropdownItems.removeClass(isCheckedClass);
      }
    });

    $modal.on('hidden.bs.modal', function () {
      const $filterInputs = $modal.find('.js-price-plan-filter-input-modal');
      const isSaving = $modal.data('saving');

      if (!isSaving) {
        $filterInputs.each(function(index, filterInput) {
          const $filterInput = $(filterInput);
          const originalState = $filterInput.data('original-state');
          $filterInput.prop('checked', originalState);
          const $dropdownItem = $filterInput.closest('.js-dropdown-item');
          updateDropdown($dropdownItem, originalState);
        });
      } else {
        $filterInputs.each(function(index, filterInput) {
          const $filterInput = $(filterInput);
          const activeElements = $filterInput.data('active-elements');
          const $inputSelected = $tabSection.find($(`.js-price-plan-filter-input.js-item-select[data-active-elements='${activeElements}']`));
          const $dropdownItem = $inputSelected.closest('.js-dropdown-item');

          const inputIsChecked = $filterInput.is(':checked');
          $inputSelected.prop('checked', inputIsChecked);
          updateDropdown($dropdownItem, inputIsChecked);
        });

        if (isMultiFilter) {
          applyMultiFilters();
        } else {
          applySingleFilters();
        }
      }

      $modal.data('saving', false);
    });

    $modal.on('shown.bs.modal', function () {
      const $filterInputs = $modal.find('.js-price-plan-filter-input-modal');
      $filterInputs.each(function(index, filterInput) {
        const $filterInput = $(filterInput);
        $filterInput.data('original-state', $filterInput.is(':checked'));
      });
    });
  }

  function updateDropdown($dropdownItem, isChecked) {
    if (isChecked) {
      $dropdownItem.addClass(isCheckedClass);
    } else {
      $dropdownItem.removeClass(isCheckedClass);
    }
  }

  invalidShowModal($modalItemFilter, 1);
  invalidShowModal($modalItemFilter1, 3);

  function invalidShowModal(ele, tabTtem) {
    const $modalPricePlanItem = ele.find('.js-modal-price-plan-filter-item.is-hidden-modal');

    if ($modalPricePlanItem.length === tabTtem) {
      ele.modal('hide');
    }
  }

  function isDesktop() {
    return window.innerWidth >= 768;
  }

  handleCardFilter('#filterPricePlans-0', '#pricePlansContent-0', 2, false);
  handleCardFilter('#filterPricePlans-1', '#pricePlansContent-1', 3, true);
});
