(function () {
  $('select').each(function () {
    const $this = $(this),
      numberOfOptions = $(this).children('option').length;
    $this.addClass('s-hidden');
    $this.wrap('<div class="select"></div>');
    $this.after('<div class="styledSelect"></div>');
    const $styledSelect = $this.next('div.styledSelect');
    $styledSelect.text($this.children('option').eq(0).text());
    const $list = $('<ul />', {
      'class': 'options'
    }).insertAfter($styledSelect);
    for (let i = 0; i < numberOfOptions; i++) {
      $('<li />', {
        text: $this.children('option').eq(i).text(),
        rel: $this.children('option').eq(i).val()
      }).appendTo($list);
    }
    const $listItems = $list.children('li');

    $styledSelect.click(function (e) {
      var selectElement = $(e.target).siblings('select');
      if(!selectElement || selectElement.prop('disabled')) return;
      e.stopPropagation();
      $('div.styledSelect.active').each(function () {
        $(this).removeClass('active').next('ul.options').hide();
      });
      $(this).toggleClass('active').next('ul.options').toggle();
    });
    $listItems.click(function (e) {
      e.stopPropagation();
      let isChanged = false;
      if ($styledSelect.text() !== $(this).text())
        isChanged = true;

      $styledSelect.text($(this).text()).removeClass('active');
      let $clickedItem = $(this);
      let $parentDiv  = $($clickedItem.closest('.select'));
      let $selectEle = $parentDiv.find('select');


      $selectEle.val($clickedItem.attr('rel'));
      if (isChanged)
        $selectEle.change();

      $list.hide();
    });

    $(document).click(function () {
      $styledSelect.removeClass('active');
      $list.hide();
    });
  });
})();

