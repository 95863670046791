$(document).ready(function () {
  const sectionLifePlan = '.js-section-life-plans';
  const sectionPricePlans = '.js-section-price-plans';
  const sectionBusinessPlans = '.js-section-business-plans';
  const sectionOemPlans = '.js-section-oem-plans';
  const sectionRewards = '.js-section-rewards';
  const containerCard = '.js-card-container';
  const planCard = '.js-plan-card';
  const marginLeft = 'is-margin-left';
  const marginRight = 'is-margin-right';
  const marginAuto = 'is-margin-auto';
  const twoColDesktop = 'col-desktop-2';
  const twoColTablet = 'col-tablet-2';

  function configColumnLayoutCard(section){
    const $containerCard = $(section).find(containerCard);

    if (!$containerCard.hasClass(twoColDesktop) &&  !$containerCard.hasClass(twoColTablet)) {
      return;
    }

    if (window.isMobileScreen()) {
      $containerCard.find('.js-card').removeClass([ marginLeft, marginRight, marginAuto ]);
      return;
    }

    const $cards = $containerCard.find('.js-card:visible');

    const numberOfCards = $cards.length;

    $cards.each(function (index, element) {
      const $planCard =$(element).find(planCard);

      if (index === numberOfCards - 1 && numberOfCards % 2 === 1) {
        $planCard.addClass(marginAuto);
        $planCard.removeClass([ marginLeft, marginRight ]);
      } else if (index % 2 === 0) {
        $planCard.addClass(marginLeft);
        $planCard.removeClass([ marginRight, marginAuto ]);
      } else if (index % 2 === 1) {
        $planCard.addClass(marginRight);
        $planCard.removeClass([ marginLeft, marginAuto ]);
      }
    });
  }

  configColumnLayoutCard(sectionLifePlan);
  configColumnLayoutCard(sectionPricePlans);
  configColumnLayoutCard(sectionBusinessPlans);
  configColumnLayoutCard(sectionOemPlans);
  configColumnLayoutCard(sectionRewards);

  window.configColumnLayoutCard = configColumnLayoutCard;

  $(window).on('resize', function() {
    configColumnLayoutCard(sectionLifePlan);
    configColumnLayoutCard(sectionPricePlans);
    configColumnLayoutCard(sectionBusinessPlans);
    configColumnLayoutCard(sectionOemPlans);
    configColumnLayoutCard(sectionRewards);
  });

  function copyTextToClipboard($element) {
    const $copyCodeElement = $element.find('.js-copy-code');
    const textToCopy = $copyCodeElement.text();
    const copiedText = $element.find('.js-copy-tooltip').attr('data-copied-text');
    const $tempTextarea = $('<textarea>');

    $tempTextarea.val(textToCopy);
    $tempTextarea.css({ position: 'absolute', left: '-9999px' });

    $('body').append($tempTextarea);

    $tempTextarea[0].select();
    document.execCommand('copy');

    $tempTextarea.remove();

    $element.addClass('copied');
    $element.find('.js-copy-tooltip').text(copiedText);
  }

  function applyPromoHover() {
    const promoHover = $('.js-promo-hover');
    const windowWidth = $(window).width();

    promoHover.each(function () {
      const currentElement = $(this);

      if (currentElement.hasClass('disabled')) {
        const fullIcon = currentElement.attr('data-full-path');
        currentElement.find('img').attr('src', fullIcon);

        if (windowWidth > 1199) {
          currentElement.hover(
            function () {
              const fullText = currentElement.find('.js-copy-tooltip').attr('data-disabled');
              currentElement.find('.js-copy-tooltip').text(fullText);
            }
          );
        } else {
          currentElement.on('click', function () {
            const tooltip = currentElement.find('.js-copy-tooltip');
            const fullText = currentElement.find('.js-copy-tooltip').attr('data-disabled');
            currentElement.find('.js-copy-tooltip').text(fullText);

            setTimeout(function () {
              tooltip.addClass('hide');
            }, 1000);

            tooltip.removeClass('hide');
          });
        }
      } else if (!currentElement.hasClass('disabled')) {

        if (windowWidth > 1199) {
          currentElement.hover(
            function () {
              const hoverIcon = currentElement.attr('data-hover-path');
              const copyText = currentElement.find('.js-copy-tooltip').attr('data-copy-text');
              currentElement.find('img').attr('src', hoverIcon);
              currentElement.find('.js-copy-tooltip').text(copyText);
            },
            function () {
              const icon = currentElement.attr('data-icon-path');
              currentElement.find('img').attr('src', icon);
            }
          );

          currentElement.on('click', function () {
            copyTextToClipboard(currentElement);
          });
        } else {
          currentElement.on('click', function () {
            const tooltip = currentElement.find('.js-copy-tooltip');
            const icon = currentElement.attr('data-icon-path');
            currentElement.find('img').attr('src', icon);

            setTimeout(function () {
              tooltip.addClass('hide');
            }, 1000);

            tooltip.removeClass('hide');
            copyTextToClipboard(currentElement);
          });
        }
      }
    });
  }

  applyPromoHover();

  $(window).on('resize', function() {
    applyPromoHover();
  });
});
