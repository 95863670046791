$(document).ready(function () {
  const $mastheadSliders = $('.masthead-slider');
  if (!$mastheadSliders.length) return;

  $mastheadSliders.each(function (i) {
    const $mastheadSlider = $(this);
    let delay = $mastheadSlider.attr('data-delay');
    let speed = $mastheadSlider.attr('data-speed');
    const $sliderCarouselImgs = $mastheadSlider.find('.js-carousel-image');
    const $sliderCarouselImgItem = $sliderCarouselImgs.find('.masthead-content__image');
    const $sliderCarouselContents = $mastheadSlider.find('.js-carousel-desc');

                     
    $sliderCarouselImgs.each(function (j) {
      const suffix = i + "" + j;
      $(this).addClass('slider-' + suffix).slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        autoplay: true,
        autoplaySpeed: delay,
        speed: speed,
        asNavFor: '.js-carousel-desc.slider-' + suffix,
        focusOnSelect: true,
        pauseOnHover: true
      });

      $(window).on('scroll', function () {
        const initialYOffset = $sliderCarouselImgs.offset().top;
        const scrollPos = $(this).scrollTop();
        const parallaxOffset = (scrollPos - initialYOffset) * 0.1;
        $sliderCarouselImgItem.css('transform', `translate3d(0, ${parallaxOffset}px, 0)`);
      });
    });

                            
    $sliderCarouselContents.each(function (j) {
      const suffix = i + "" + j;
      $(this).addClass('slider-' + suffix).slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: false,
        dots: true,
        fade: true,
        speed: speed,
        asNavFor: '.js-carousel-image.slider-' + suffix,
        focusOnSelect: true,
        pauseOnHover: true
      });

      const slideItem = $(this).find('.masthead-content');
      const contentInfo = slideItem.find('.masthead-content__info.single');

      if (slideItem.length <= 1) {
        $(this).find('.slick-dots').css('display', 'none');
        $(this).css('margin-bottom', 0);
      }

      const breadcrumb = $('.masthead-content__breadcrumbs');
      const breadcrumbItems = breadcrumb.find('.masthead-content__breadcrumbs-item');
      const showMore = breadcrumb.find('.show-more');
      const slashMore = breadcrumb.find('.icon-slash.after');
      const breadcrumbBox = $('.breadcrumb-box');
      const breadcrumbUl = $('.breadcrumb-box ul');

      let isExpanded = false;

      function updateBreadcrumbVisibility() {
        breadcrumbItems.each(function (index) {
          if (index === 0 || index === breadcrumbItems.length - 1) {
            $(this).show();
          } else {
            $(this).hide();
          }
        });

        if (breadcrumbItems.length > 2) {
          showMore.show();
          slashMore.show();
        } else {
          showMore.hide();
          slashMore.hide();
        }
      }

      updateBreadcrumbVisibility();

      showMore.on('click', function () {
        if (!isExpanded) {
          breadcrumbUl.empty();
          breadcrumbItems.each(function (index) {
            if (index > 0 && index < breadcrumbItems.length - 1) {
              breadcrumbUl.append($('<li>').append($(this).clone()));
            }
          });
          breadcrumbBox.show();
        } else {
          breadcrumbBox.hide();
          updateBreadcrumbVisibility();
        }
        isExpanded = !isExpanded;
      });
    });
  });
});
