$(document).ready(function () {
    var cisForm = $('.js-form-cis-submit');    
    var submitBtn = cisForm.find('.js-cis-submit');

    cisForm.on('submit', (e) => {
        e.preventDefault();
        var currentForm = $(e.currentTarget);
        var msgPlaceholder = currentForm.find('.js-form-cis-msg');
        
        cisFormSubmitBtnDisable();
        
        //Submit via ajax
        $.ajax({
            method: "POST",
            url: currentForm.attr('action'),
            data: currentForm.serialize(),                     
            success: function (response) {
                //show msg
                if(response.status){
                    msgPlaceholder.addClass('success').removeClass('error');
                }else{
                    msgPlaceholder.addClass('error').removeClass('success');
                }
                msgPlaceholder.text(response.data);
                msgPlaceholder.removeClass('hidden');
                //clear form
                cisFormReset(currentForm);     
                cisFormSubmitBtnEnable();  
                
            },
            error: function(xhr, status, error) {
                console.log(error);
                msgPlaceholder.addClass('error').removeClass('success').removeClass('hidden');
                msgPlaceholder.text("Unable submit. Please try again later.");
                cisFormSubmitBtnEnable();               
            }
        });
    });

    function cisFormReset(currentForm){
        currentForm.trigger("reset");
        var dropdowns = currentForm.find("select");
        dropdowns.each(function( index ) {
            $(this)[index].value = $(this)[index].options[0].value;
            if($(this)[index].nextElementSibling.className == "styledSelect"){
                $(this)[index].nextElementSibling.innerHTML = $(this)[index].options[0].text;
            }
        });      
    }

    function cisFormSubmitBtnDisable(){
        submitBtn.find('.js-cis-submit-text').html(submitBtn.data('loading'));
        submitBtn.find('.js-loading').addClass('icon-loading-spinner');
        submitBtn.attr('disabled','disabled');
    }

    function cisFormSubmitBtnEnable(){
        submitBtn.find('.js-cis-submit-text').html(submitBtn.data('text'));
        submitBtn.find('.js-loading').removeClass('icon-loading-spinner');
        submitBtn.removeAttr('disabled');
    }
});