$(document).ready(function () {
  let $suggestionDrp;

  const data = $('[data-search-data]').data('search-data');

  if (!data) return;

  function handleDropDownSuggestion () {
    $suggestionDrp = $('.js-suggestion').drpAutoComplete();
  }

  function populateDate (data) {
    populateSuggestion(data);
  }

  function populateSuggestion (data) {
    autoRenderHTML('suggestionTemplate', data);
    handleDropDownSuggestion(data);
  }

  function autoRenderHTML (idTemplate, data) {
    const $templateContainer = $('#' + idTemplate);

    const $subItemTemplate = $('#itemTemplate');
    const $itemTemplate = $($subItemTemplate.html());

    data.forEach(item => {
      const $newItem = $itemTemplate.clone();
      $newItem.find('a').attr('data-value', item);
      $newItem.find('a').html(item);

      $templateContainer.append($newItem);
    });
  }

  populateDate(data);
});
