$(document).ready(function () {
  const $contentSlider = $('#content-slider');
  if (!$contentSlider.length) return;

  $contentSlider.each(function () {
    const $contentSliderFirstImg = $('.js-content-slider-1');
    const $contentSliderSecondImg = $('.js-content-slider-2');
    const $contentSliderDesc = $('.js-content-slider-desc');

    let numSlickFirstImg = 0;
    $contentSliderFirstImg.each(function () {
      numSlickFirstImg++;
      $(this).addClass('slider-' + numSlickFirstImg).slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: false,
        autoplaySpeed: $(this).data('autoplaySpeed') || 5000,
        speed: 400,
        asNavFor: '.js-content-slider-2.slider-' + numSlickFirstImg + ', .js-content-slider-desc.slider-' + numSlickFirstImg,
        focusOnSelect: true,
        pauseOnHover: true,
        draggable: false,
        responsive: [ {
          breakpoint: 768,
          settings: {
            dots: false,
            arrows: false,
            autoplay: false
          }
        } ]
      });
    });

    let numSlickSecImg = 0;
    $contentSliderSecondImg.each(function () {
      numSlickSecImg++;
      $(this).addClass('slider-' + numSlickSecImg).slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: false,
        autoplaySpeed: $(this).data('autoplaySpeed') || 5000,
        speed: 400,
        asNavFor: '.js-content-slider-1.slider-' + numSlickSecImg + ', .js-content-slider-desc.slider-' + numSlickSecImg,
        focusOnSelect: true,
        pauseOnHover: true,
        draggable: false,
        responsive: [ {
          breakpoint: 768,
          settings: {
            autoplay: false
          }
        } ]
      });
    });

    let numSlickDesc = 0;
    $contentSliderDesc.each(function () {
      numSlickDesc++;
      $(this).addClass('slider-' + numSlickDesc).slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        autoplay: false,
        autoplaySpeed: $(this).data('autoplaySpeed') || 5000,
        speed: 400,
        asNavFor: '.js-content-slider-2.slider-' + numSlickDesc + ', .js-content-slider-1.slider-' + numSlickDesc,
        arrow: true,
        fade: true,
        focusOnSelect: true,
        pauseOnHover: true,
        draggable: false,
        responsive: [ {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
          }
        }, {
          breakpoint: 768,
          settings: {
            autoplay: false
          }
        } ]
      });
    });

    function moveImageOnScroll() {
      $('.js-content-slider').each(function() {
        const component = $(this);
        const movingImage = component.find('.js-content-slider-item');
        const contentText = component.find('.js-third-container');
        const componentTop = component.offset().top;
        const scrollPosition = $(window).scrollTop();

        const contentHeight = contentText.height();
        const imageHeight = movingImage.height();

        if (scrollPosition >= componentTop && contentHeight > imageHeight) {
          const maxTopPosition = contentHeight - imageHeight - 64;
          const newTopPosition = Math.min(scrollPosition - componentTop, maxTopPosition);
          movingImage.css('transform', 'translateY(' + newTopPosition + 'px)');
        } else {
          movingImage.css('transform', 'translateY(0)');
        }
      });
    }

    function updateImageOnResize() {
      if ($(window).width() >= 991) {
        $(window).scroll(moveImageOnScroll);
        moveImageOnScroll();
      } else {
        $(window).off('scroll', moveImageOnScroll);
      }
    }

    updateImageOnResize();

    $(window).on('resize', function () {
      $contentSliderFirstImg.slick('setPosition');
      $contentSliderSecondImg.slick('setPosition');
      $contentSliderDesc.slick('setPosition');
      updateImageOnResize();
    });
  });
});
