$(document).ready(function () {

  $('.js-referral-form-submit').submit(function (e) {
    const msgNullInput = $(this).find('.js-err-null-input').val();
    const $jsNotification = $(this).find('.js-input-notification');
    const $jsInputReferralCode = $(this).find('.js-referral-code');

    clearNotificationClass($jsNotification);

    if ($jsInputReferralCode.val().trim() === '') {
      displayError($jsNotification, msgNullInput);
      e.preventDefault();
      return;
    }
  });

  function clearNotificationClass($notification) {
    $notification.removeClass('error');
    $notification.hide();
  }

  function displayError($notification, msgError) {
    $notification.addClass('error');
    $notification.text(msgError);
    $notification.show();
  }
});
