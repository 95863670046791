$(document).ready(function () {
  const $contentBlock = $('.js-content-block');
  const $root = $contentBlock.parents('.row');

  function setHeightForCardDesktop(sections) {
    if (!sections || !sections.length) return;

    $(sections).each(function(idx, section) {
      const cardRatio = $(section).data('card-ratio');
      const $cards = $(section).find('.js-item-same-height');

      makeCardsSameHeight($cards, parseFloat(cardRatio));
    });
  }

  function makeCardsSameHeight($cards, cardRatio) {
    if (!$cards.length || $(window).width() < 768 ) {
      return;
    }

    $cards.height('auto');

    var maxHeight = $cards.height();
    var maxWidth = $cards.width();
    $cards.each(function(index, el) {
      const heightCurrent = $(el).height();
      if (maxHeight < heightCurrent) {
        maxHeight = heightCurrent;
      }

      const widthCurrent = $(el).width();
      if (maxWidth < widthCurrent) {
        maxWidth = widthCurrent;
      }
    });

    if (cardRatio) {
      var maxHeightRatio = maxWidth * cardRatio;
      if (maxHeight < maxHeightRatio) {
        maxHeight = maxHeightRatio;
      }
    }

    if ($(window).width() >= 768) {
      $cards.height(maxHeight);
    }
  }

  function setHeightForCardMobile(section) {
    $(section).find('.js-item-same-height').css('height', 'auto');
  }

  function setHeightForCard() {
    if($(window).width() >= 768) {
      setHeightForCardDesktop($root);
    } else {
      setHeightForCardMobile($root)
    }
  }

  setHeightForCard();

  $(window).on('resize', function(){
    setHeightForCard();
  });
});
