$(document).ready(function(){
  const imageItem = $('.accordion-slide__image');
  const imageItemFirst = $('.accordion-slide__image:first-child');
  const imageItemLast = $('.accordion-slide__image:last-child');
  const slider = $('#accordion-slide');
  const numberOfImages = imageItem.length;
  let dividerDesktop, dividerMobile, itemWidthDesktop, itemWidthMobile;

  const loaded = true;

  if (numberOfImages === 3) {
    dividerDesktop = 8;
    dividerMobile = 6;
    itemWidthDesktop = 1.1;
    itemWidthMobile = 1;
  } else if (numberOfImages === 4) {
    dividerDesktop = 8;
    dividerMobile = 7;
    itemWidthDesktop = 1.3;
    itemWidthMobile = 1.2;
  } else if (numberOfImages === 5) {
    dividerDesktop = 10;
    dividerMobile = 8;
    itemWidthDesktop = 1.4;
    itemWidthMobile = 1.3;
  }

  imageItemFirst.addClass('image-first');
  imageItemLast.addClass('image-last');

  function adjustImageSize() {
    if ($(window).width() > 1366) {
      imageItem.width(slider.width() / dividerDesktop);
    } else {
      imageItem.width(slider.width() / dividerMobile);
    }
  }

  function resetImageStyles() {
    imageItem.find('.accordion-slide__slider-image').addClass('overlay');
    imageItem.find('.accordion-slide__content').addClass('overlay');
  }

  imageItem.click(function(event){
    event.stopPropagation();

    if ($(window).width() <= 1366) {
      resetImageStyles();
      imageItem.width(slider.width() / dividerMobile);
      $(this).width(slider.width() / itemWidthMobile);
      $(this).find('.accordion-slide__slider-image').removeClass('overlay');
      $(this).find('.accordion-slide__content').removeClass('overlay');
    } else if ($(window).width() > 1366) {
      resetImageStyles();
      imageItem.width(slider.width() / dividerDesktop);
      $(this).width(slider.width() / itemWidthDesktop);
      $(this).find('.accordion-slide__slider-image').removeClass('overlay');
      $(this).find('.accordion-slide__content').removeClass('overlay');
    }

    if ($(window).width() <= 991) {
      imageItem.find('.accordion-slide__slider-image').removeClass('overlay');
      imageItem.find('.accordion-slide__content').removeClass('overlay');
    }
  });

  adjustImageSize();

  setTimeout(function () {
    slider.find('.accordion-slide__content.js-matchHeight').matchHeight();
  }, 100);

  $(window).resize(function() {
    adjustImageSize();
    imageItemFirst.mouseover();
    imageItemFirst.click();
    slider.find('.accordion-slide__content.js-matchHeight').matchHeight();
  });

  $(window).on('load', function() {
    setTimeout(function () {
      slider.find('.accordion-slide__content.js-matchHeight').matchHeight();
    }, 100);
  });

  imageItemFirst.mouseover();
  imageItemFirst.click();
});
