(function (root, factory) {
  // eslint-disable-next-line no-undef
  if (typeof define === 'function' && define.amd) {
    // eslint-disable-next-line no-undef
    define([ 'jquery' ], function (jquery) {
      if (!jquery.fn) jquery.fn = {};
      return factory(jquery);
    });
  } else if (typeof module === 'object' && module.exports) {
    var jQuery = (typeof window !== 'undefined') ? window.jQuery : undefined;
    if (!jQuery) {
      jQuery = require('jquery');
      if (!jQuery.fn) jQuery.fn = {};
    }
    module.exports = factory(jQuery);
  } else {
    root.drpAutoComplete = factory(root.jQuery);
  }
}(window, function ($) {
  var activeClass = 'active';

  var DrpAutoComplete = function (el, options) {
    this.options = options || {};
    this.currentIndex = -1;
    this.document = $(document);

    this.container = $(el);
    this.container.allItems = this.container.find('ul > li > a');

    this.container.itemNoResult = this.getItemNoResult();
    this.container.items = this.getItemsAbleToShow();

    this.container.inputText = this.container.find('input[type="text"]');
    this.container.dropdown = this.container.find('div[data-toggle="dropdown"]');
    this.container.dropdownContainer = this.container.find('.dropdown-menu');
    this.container.dropdownArrow = this.container.find('.js-drp-arrow');

    this.document.on('mouseup', $.proxy(this.handleToggleDropdown, this));
    this.container.on('keydown', $.proxy(this.handleKeyDown, this));
    this.container.on('show.bs.dropdown', $.proxy(this.handleEventDropdownShow, this));

    this.container.allItems.on('click', $.proxy(this.handleSelectedItem, this));

    this.container.inputText.on('input', $.proxy(this.handleInputChange, this));
    this.container.inputText.on('focus', $.proxy(this.handleInputFocus, this));
    this.container.inputText.on('blur, focusout', $.proxy(this.handleInputFocusOut, this));

    this.container.dropdown.on('click', $.proxy(this.handleClickOnDropdown, this));
    this.container.dropdownArrow.on('click', $.proxy(this.handleClickOnDropdownArrow, this));
  };

  DrpAutoComplete.prototype = {
    constructor: DrpAutoComplete,

    getItemNoResult: function () {
      return this.container.find('.js-drp-item-no-result');
    },

    getItemsAbleToShow: function () {
      return this.container.allItems.filter(function (index, item) {
        var isHidden = $(item).data('hidden');

        return !isHidden;
      }).not(this.container.itemNoResult);
    },

    getItemsVisible: function () {
      return this.container.allItems.filter(function (index, item) {
        return $(item).is(':visible');
      }).not(this.container.itemNoResult);
    },

    handleSelectedItem: function (e) {
      var target = $(e.target);

      var value = $(target).data('value');

      this.container.inputText.val(value);
      this.container.inputText.change();

      this.container.items.parent().show();
      this.container.itemNoResult.parent().hide();

      this.container.trigger('selectedItem.drpAutoComplete', target);

      this.container.dropdown.dropdown('hide');

      e.stopPropagation();
    },

    handleInputChange: function (e) {
      var target = $(e.target);
      var searchValue = target.val().toLowerCase() || target.val();

      this.container.inputText.change();
      this.container.dropdown.dropdown('show');
      this.container.allItems.removeClass(activeClass);
      this.container.trigger('inputChange.drpAutoComplete', searchValue);

      var itemsAbleToShow = this.getItemsAbleToShow();

      if (!searchValue) {
        itemsAbleToShow.parent().show();
        this.container.itemNoResult.parent().hide();
      }

      var text;
      var itemsMatched = itemsAbleToShow.filter(function (index, item) {
        text = $(item).text().toLowerCase();
        return text.indexOf(searchValue) > -1;
      });

      itemsMatched.each(function() {
        var $element = $(this);

        var textContent = $element.text();
        var startIndex = textContent.toLowerCase().indexOf(searchValue);
        var endIndex = startIndex + searchValue.length;

        if (startIndex >= 0) {
          var htmlContent = textContent.substring(0, startIndex);
          htmlContent += '<b>' + textContent.substr(startIndex, searchValue.length) + '</b>';
          htmlContent += textContent.substr(endIndex);
          $element.html(htmlContent);
        }
      });

      itemsAbleToShow.parent().hide();
      itemsMatched.parent().show();

      if (!itemsMatched || !itemsMatched.length) {
        this.container.itemNoResult.parent().show();
      } else {
        this.container.itemNoResult.parent().hide();
      }
    },

    handleInputFocusOut: function (e) {
      e.stopImmediatePropagation();
    },

    handleInputFocus: function () {
      this.container.dropdown.dropdown('show');

      this.container.inputText.select();
    },

    handleClickOnDropdown: function (e) {
      this.container.inputText.focus();

      e.stopImmediatePropagation();
      e.preventDefault();
    },

    handleClickOnDropdownArrow: function (e) {
      if (this.isDropdownShown()) {
        this.container.dropdown.dropdown('hide');
      } else {
        this.container.inputText.focus();
      }

      e.stopImmediatePropagation();
      e.preventDefault();
    },

    handleToggleDropdown: function (e) {
      if (!this.container.is(e.target)
        && this.container.has(e.target).length === 0) {
        this.container.dropdown.dropdown('hide');
      }
    },

    handleEventDropdownShow: function () {
      this.container.allItems.removeClass(activeClass);

      this.getItemsAbleToShow().parent().show();
      this.getItemNoResult().parent().hide();

      this.currentIndex = -1;
    },

    activeItemByIndex: function (items, index) {
      var activeItem = $(items[index]);
      var value = activeItem.data('value');

      $(items).removeClass(activeClass);
      activeItem.addClass(activeClass);

      var position = activeItem.position();
      var height = activeItem.outerHeight();

      var ddScrollTop = this.container.dropdownContainer.scrollTop();
      var ddHeight = this.container.dropdownContainer.height();

      if (position.top + ddScrollTop < ddScrollTop) {
        this.container.dropdownContainer.scrollTop(ddScrollTop + position.top);
      } else {
        if (position.top + height > ddHeight) {
          this.container.dropdownContainer.scrollTop(
            position.top + ddScrollTop + height - ddHeight
          );
        }
      }

      this.container.inputText.val(value);
      this.container.inputText.change();

      if (value) {
        this.container.trigger('selectedItem.drpAutoComplete', [ activeItem, true ]);
      }
    },

    handleKeyDown: function (e) {
      var keyCode = e.which || e.keyCode;
      if (keyCode !== 38 && keyCode !== 40 && keyCode !== 13) {
        return;
      }

      var items = this.getItemsVisible();
      if (!items || !items.length) {
        items = [ this.container.itemNoResult ];
      }

      var lastIndex = items.length - 1;

      switch (e.which || e.keyCode) {
        case 38:
          this.currentIndex = this.currentIndex - 1;
          if (this.currentIndex < 0) {
            this.currentIndex = lastIndex;
          }

          this.activeItemByIndex(items, this.currentIndex);

          break;

        case 40:
          this.currentIndex = this.currentIndex + 1;
          if (this.currentIndex > lastIndex) {
            this.currentIndex = 0;
          }

          this.activeItemByIndex(items, this.currentIndex);

          break;

        case 13:
          this.container.dropdown.dropdown('hide');
          this.container.dropdownContainer.scrollTop(0);
          this.container.inputText.blur();

          if (items.length === 1) {
            this.container.parents('form').submit();
            // this.activeItemByIndex(items, 0);
          } else {
            if (this.currentIndex >= 0) {
              this.container.trigger('selectedItem.drpAutoComplete', items[this.currentIndex]);
            }

            // submit form
            this.container.parents('form').submit();
          }

          break;

        default:

      }
    },

    isDropdownShown: function () {
      return this.container.hasClass('show');
    },

    getMatchedItems: function (value) {
      var items = this.getItemsAbleToShow();

      var text;
      return items.filter(function (index, item) {
        text = $(item).text().toLowerCase();

        return text === value.toLowerCase();
      });
    }
  };

  $.fn.drpAutoComplete = function (options) {
    new DrpAutoComplete($(this), options);

    return $(this);
  };

  return DrpAutoComplete;
}));
