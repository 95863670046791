$(document).ready(function () {
  const $menuContainer = $('.js-automenu-container');
  const $menuMoreContainer = $('.js-automenu-more-container');
  const $menuItems = $('.js-subnavigation-item');
  const $subMenuDropdownContainer = $('.js-sub-menu-dropdown-container');
  const $subMenuDropdownTemplate = $($('#sub-menu-dropdow-template').html());
  const $automenuContainer = $('.js-automenu-container');
  const $activeSubDropdownMenuLinks = $automenuContainer.find('.js-subnavigation-item');
  const $activeMenuItem = $activeSubDropdownMenuLinks.filter('.active');
  const $dropdownActiveItem = $menuMoreContainer.find('.js-dropdown-arrow-up.active');


  if (!$menuContainer.length || !$menuItems.length || !$menuMoreContainer.length) {
    return;
  }

  populateSubDropdownMenu();
  updateSubnavigation();

  function updateSubnavigation() {
    if (window.isMobileScreen()) {
      $menuItems.css('display', '');
      $('.js-subnavigation-more-item').css('display', '');
      return;
    }

    const menuContainerWidth = $menuContainer.width();
    const menuMoreContainerWidth = $menuMoreContainer.outerWidth(true);
    const numberOfMenuItem = $menuItems.length;
    const menuItemAvailableWidth = menuContainerWidth - (menuMoreContainerWidth + 50);

    let menusWidth = 0;
    for (let i = 0; i < numberOfMenuItem; i++) {
      const $menuItem = $($menuItems[i]);
      menusWidth += $menuItem.outerWidth(true);
      const itemIndex = $menuItem.data('menu-index');

      if (menusWidth > menuItemAvailableWidth) {
        $menuItem.css('display', 'none');
        $(`.js-subnavigation-more-item[data-menu-index='${itemIndex}']`).css('display', '');
      } else {
        $menuItem.css('display', '');
        $(`.js-subnavigation-more-item[data-menu-index='${itemIndex}']`).css('display', 'none');
      }
    }

    if (menuItemAvailableWidth > menusWidth) {
      $menuMoreContainer.hide();
    } else {
      $menuMoreContainer.show();
    }
  }

  function populateSubDropdownMenu() {
    $dropdownActiveItem.find('span').first().html($activeMenuItem.html());

    $activeSubDropdownMenuLinks.each(function (index, element) {
      const $originLink = $(element);
      const $newAElement = $subMenuDropdownTemplate.clone();
      $newAElement.attr('href', $originLink.attr('href'));
      $newAElement.html($originLink.html());
      $newAElement.attr('id', `more-${$originLink.attr('id')}`);
      $newAElement.attr('data-menu-index', index);
      $subMenuDropdownContainer.append($newAElement);
    });
  }

  $(window).resize(function () {
    updateSubnavigation();
  });

  $('.js-dropdown-arrow-up').on('click', function () {
    $('.js-icon-dropdown').toggleClass('icon-arrow-down icon-arrow-up');
  });
});
