$(document).ready(function () {
  const $articleForms = $("#article-form")
  if ($articleForms.length) {
    $articleForms.each(function () {
      const $form = $(this);
      const mobileResults = $form.data('mobile-results');
      const desktopResults = $form.data('desktop-results');
      const $articleId = $form.siblings('#articleListing');
      const $cardsWrapper = $articleId.find(".article-card");
      const $paginationWrapper = $articleId.find(".article-pagination");
      const $filterButton = $('.article-category .js-filter-button .article-category__selected');
      const $filterButtonList = $('.article-category .js-filter-button li');
      let maxHeightDescTop = 0;
      let maxHeightDescBottom = 0;
      let maxHeightPBotton = 0;

      function parametersChanged(focus) {
        $.ajax({
          url: $form.attr('action'),
          method: $form.attr('method'),
          data: $form.serialize(),
          dataType: 'json',
          error: function (response) {
            console.log("Failed to submit change");
            console.log(response);
          },
          success: function (response) {
            if (response.success) {
              if (focus) {
                $form.get(0).scrollIntoView({ behavior: 'smooth' });
              }
              $cardsWrapper.empty();
              $cardsWrapper.append(response.html.searchResult);

              $paginationWrapper.empty();
              $paginationWrapper.append(response.html.pagination);

              setEqualHeight();
              paginationSubscribeEvent();
              cardResultSybscribeEvent();
            }
            else {
              console.log("Submit change unsuccess");
              console.log(response);
            }
          }
        });
      }

      function checkViewPort() {
        if (window.innerWidth > 991 && $form.find('#itemsPerPage').val() != desktopResults) {
          $form.find('#itemsPerPage').val(desktopResults);
          parametersChanged()
        }
        else if (window.innerWidth <= 991 && $form.find('#itemsPerPage').val() != mobileResults) {
          $form.find('#itemsPerPage').val(mobileResults);
          parametersChanged()
        }
      }
      checkViewPort();

      $filterButton.on('click', function () {
        const $button = $(this).parent('.js-filter-button');
        const $selected = $button.find('.article-category__selected');
        const $list = $button.find('.article-category__items');

        const $ex = $filterButton.filter(function () {
          return $(this).parent('.js-filter-button').attr('class') != $selected.parent('.js-filter-button').attr('class');
        });
        if ($ex.length) {
          $ex.find('.article-category__selected').removeClass('open');
          $ex.find('.article-category__items').slideUp();
        }
        $selected.toggleClass('open');
        $list.slideToggle();
      });

      $filterButtonList.on('click', function (event) {  
        const $e = $(event.target);
        const selectedText = $e.text().trim();
        const $wrapper = $e.parents(".js-filter-button");
        const $button = $wrapper.find('.article-category__selected');
        const $selectedFilter = $wrapper.find('span');
        let flag = false;

        if ($wrapper.hasClass("js-filter-sort")) {
          const $sort = $form.find('#descSort');
          let currentValue = $e.data('sort') == "desc";
          if ($sort.val() != currentValue) {
            $sort.val(currentValue);
            flag = true;
          }
        } else if ($wrapper.hasClass("js-filter-type")) {
          const $category = $form.find('#category');
          let currentValue = selectedText.includes('All ') ? "" : selectedText;
          if ($category.val() != currentValue) {
            $category.val(currentValue);
            $form.find('#page').val('1');
            flag = true;
          }
        } else if ($wrapper.hasClass("js-filter-year")) {
          const $yearInput = $form.find('#year');
          let currentValue = selectedText.includes('All ') ? "" : selectedText;
          if ($yearInput.val() != currentValue) {
            $yearInput.val(currentValue);
            $form.find('#page').val('1');
            flag = true;
          }
        }
        if($button.hasClass('open')) {
          $button.trigger("click");
        }
        if (flag) {
          $selectedFilter.text(selectedText);
          $e.siblings().removeClass('active');
          $e.addClass('active');
          parametersChanged();
        }
      });

      function paginationSubscribeEvent() {
        const $articlePagination = $paginationWrapper.find('.article-pagination__pages');
        $articlePagination.on('click', 'a', function (event) {
          event.preventDefault();
          updatePage($(this).text());
        });

        $articlePagination.on('click', '.article-pagination__prev', function () {
          updatePage($(this).data('page'));
        });

        $articlePagination.on('click', '.article-pagination__next', function () {
          updatePage($(this).data('page'));
        });
      }

      function cardResultSybscribeEvent() {
        const $category = $cardsWrapper.find(".article-card__desc--bottom")
        $category.on('click', 'a', function (event) {
          event.preventDefault();
          let cat = $(this).text();
          $filterButtonList.filter(function () {
            return this.innerHTML == cat;
          }).trigger("click");
          $form.get(0).scrollIntoView({ behavior: 'smooth' });
        });
      }

      function updatePage(page) {
        if (!page) return;

        const $page = $form.find('#page');
        if ($page.val() == page) return;

        $page.val(page);
        parametersChanged(true);
      }

      paginationSubscribeEvent();

      function setEqualHeight() {
        const $el = $articleId.find('.js-matchHeight-desc-top').matchHeight();
        maxHeightDescTop = getMaxHeight($el, maxHeightDescTop);
        $el.height(maxHeightDescTop);

        const $elc = $articleId.find('.js-matchHeight-desc-bottom').matchHeight();
        maxHeightDescBottom = getMaxHeight($elc, maxHeightDescBottom);
        $elc.height(maxHeightDescBottom);

        const $elcp = $elc.find('p').matchHeight();
        maxHeightPBotton = getMaxHeight($elcp, maxHeightPBotton);
        $elcp.height(maxHeightPBotton);
      }

      function getMaxHeight($el, def) {
        const newMaxHeight = Math.max(...$el.map(function () {
          return $(this).height();
        }).get());
        return def < newMaxHeight ? newMaxHeight : def;
      }

      setEqualHeight();

      $(window).on('resize', function () {
        setEqualHeight();
        checkViewPort();
      });

      $(window).on('load', function () {
        setEqualHeight();
        checkViewPort();
      });
    });
  } else {
    const $articleListing = $('.js-article-listing');
    const totalResults = $articleListing.length;
    const resultsPerPage = $articleListing.data('per-page');
    const totalPages = Math.ceil(totalResults / resultsPerPage);
    const $articleId = $('#articleListing');
    const $filterButton = $('.js-filter-button');
    const $filterButtonList = $('.js-filter-button li');
    const $articlePagination = $('.article-pagination__pages');
    let currentPage;

    if (!$articleId.length) return;

    function getSelectedCategory() {
      return localStorage.getItem('selectedText') || 'All Types';
    }

    function setSelectedCategory(category) {
      localStorage.setItem('selectedText', category);
    }

    function sortArticles(order) {
      const $articleCardsWrapper = $('.article-card');
      const $articleCards = $articleCardsWrapper.find('.js-article-listing');

      $articleCards.sort(function (a, b) {
        const dateA = new Date(parseDate($(a).data('date')));
        const dateB = new Date(parseDate($(b).data('date')));

        return order === 'Newest' ? dateB - dateA : dateA - dateB;
      });

      $articleCardsWrapper.empty().append($articleCards);
    }

    function getSortingOrder() {
      return $('.article-category__button.js-filter-sort .article-category__items li.active').data('sort') || 'Newest';
    }

    function parseDate(dateString) {
      const parts = dateString.split("-");
      return new Date(parts[2], parts[1] - 1, parts[0]);
    }

    $filterButton.on('click', function () {
      const $button = $(this);
      const $selected = $button.find('.article-category__selected');
      const $list = $button.find('.article-category__items');

      $selected.toggleClass('open');
      $list.slideToggle();
    });

    $filterButtonList.on('click', function (event) {
      const selectedText = $(this).text();
      const $selectedFilter = $(this).parent().siblings().find('span');

      $selectedFilter.text(getSelectedCategory());
      $selectedFilter.text(selectedText);
      setSelectedCategory(selectedText);
      $(this).siblings().removeClass('active');
      $(this).addClass('active');
      sortArticles(getSortingOrder());
    });

    function updatePaginationText(currentPage, totalResults, filterCategory) {
      const startIndex = (currentPage - 1) * resultsPerPage + 1;
      const endIndex = Math.min(startIndex + resultsPerPage - 1, totalResults);

      $('.article-pagination__text .results-start').text(startIndex);
      $('.article-pagination__text .results-end').text(endIndex);
      $('.article-pagination__text .total-results').text(totalResults);
      $('.article-pagination__text .filter-type').text(filterCategory);
    }

    function showCardsForPage(currentPage) {
      const startIndex = (currentPage - 1) * resultsPerPage;
      const endIndex = startIndex + resultsPerPage;
      $articleListing.hide().slice(startIndex, endIndex).show();
    }

    function updatePaginationNumbers(currentPage, totalPages) {
      const $paginationNumbers = $('.article-pagination__number');
      $paginationNumbers.empty();

      for (let i = 1; i <= totalPages; i++) {
        const $pageNumber = $('<a href="#" class=""></a>').text(i);

        if (i === currentPage) {
          $pageNumber.addClass('active');
        }

        $paginationNumbers.append($pageNumber);

        if (i < totalPages) {
          $paginationNumbers.append(' ');
        }
      }
    }

    function checkPaginationVisibility(totalResults) {
      if (totalResults <= resultsPerPage) {
        $('.article-pagination').hide();
      } else {
        $('.article-pagination').show();
      }
    }

    $articlePagination.on('click', 'a', function (event) {
      event.preventDefault();
      currentPage = parseInt($(this).text());
      updatePaginationText(currentPage, totalResults, 'All');
      showCardsForPage(currentPage);
      updatePaginationNumbers(currentPage, totalPages);
    });

    $articlePagination.on('click', '.article-pagination__prev', function () {
      if (currentPage > 1) {
        currentPage--;
        updatePaginationText(currentPage, totalResults, 'All');
        showCardsForPage(currentPage);
        updatePaginationNumbers(currentPage, totalPages);
      }
    });

    $articlePagination.on('click', '.article-pagination__next', function () {
      if (currentPage < totalPages) {
        currentPage++;
        updatePaginationText(currentPage, totalResults, 'All');
        showCardsForPage(currentPage);
        updatePaginationNumbers(currentPage, totalPages);
      }
    });

    currentPage = 1;

    checkPaginationVisibility(totalResults);
    updatePaginationText(currentPage, totalResults, 'All');
    updatePaginationNumbers(currentPage, totalPages);
    showCardsForPage(currentPage);
    sortArticles(getSortingOrder());

    $(window).on('resize', function () {
      $('.article-card__item').find('.js-matchHeight').matchHeight();
    });

    $(window).on('load', function () {
      $('.article-card__item').find('.js-matchHeight').matchHeight();
      sortArticles(getSortingOrder());
    });
  }
});
