$(document).ready(function () {
    const $jsInputReferralPromoCode = $('.electricity').find('.js-referral-code');
    const $ssk = global.getReferPromoSecretKey();

    $jsInputReferralPromoCode.on('change', function(){
        let name = 'senoko_' + $(this).prop('name');        
        var encryptedCode = CryptoJS.AES.encrypt($(this).val(), $ssk).toString();  
        setCookie( name, encryptedCode, "");
    });

    if($jsInputReferralPromoCode){
        $jsInputReferralPromoCode.each(function(){
            let name = 'senoko_' + $(this).prop('name');
            let cookie = getCookie(name);
            if(cookie){               
                let bytes  = CryptoJS.AES.decrypt(cookie, $ssk);
                let decryptedCode = bytes.toString(CryptoJS.enc.Utf8);
                $(this).val(decryptedCode);
            }
        });
    }

    function setCookie(name,value,days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }   
        document.cookie = name + "=" + (value || "")  + expires + "; path=/; secure";
    }
    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }
});