$(document).ready(function () {
  const $accordions = $('.accordion');
  if (!$accordions.length) return;

  $accordions.each(function () {
    const $accordion = $(this);
    const $titleSlider = $accordion.find('.js-title-slider');
    const $titleSliderParent = $accordion.find('.js-title-slider-parent');
    const $expand = $accordion.find('.js-accordion-expand');
    const $accItem = $accordion.find('.faq-accordion__item');
    const $textSlider = $accordion.find('.js-text-slider');

    changeIconAccordions(this);

    function changeIconAccordions(el) {
      $textSlider.each(function (idx, element) {
        const $element = $(element);
        const $container = $element.closest('.js-title-slider-parent');
        const labelElm = $container.find('.faq-accordion__item-label');
        const iconElm = $container.find('.faq-accordion__icon');

        if ($element.hasClass('active')) {
          $container.find('.js-title-icon').removeClass('icon-plus').addClass('icon-minus');
          labelElm.addClass('expanded');
          iconElm.addClass('rotate-icon');
        } else {
          labelElm.removeClass('expanded');
          iconElm.removeClass('rotate-icon');
        }
      });
    }

    $expand.click(function () {
      const allAccordionsExpanded = $textSlider.not('.active').length === 0;
      if (allAccordionsExpanded) {
        $textSlider.slideUp(600);
        $textSlider.removeClass('active');
        $accItem.find('.faq-accordion__item-label').removeClass('expanded');
        updateButtonText();
      } else {
        $textSlider.slideDown(600);
        $textSlider.addClass('active');
        $accItem.find('.faq-accordion__item-label').addClass('expanded');
        updateButtonText();
      }
      changeIconAccordions(this);
    });

    function updateButtonText() {
      const allAccordionsExpanded = $textSlider.not('.active').length === 0;
      const $expand = $('.js-accordion-expand');

      if (allAccordionsExpanded) {
        $expand.text('Collapse All');
      } else {
        $expand.text('Expand All');
      }
    }

    updateButtonText();

    $titleSlider.click(function () {
      const $parentElm = $(this).closest('.js-title-slider-parent');
      $parentElm.find('.js-text-slider').slideToggle(600);
      const iconElm = $(this).parent().find('.js-title-icon');
      const arrowElm = $(this).parent().find('.faq-accordion__icon');
      iconElm.toggleClass('icon-plus');
      iconElm.toggleClass('icon-minus');
      arrowElm.toggleClass('rotate-icon');

      const labelElm = $(this).find('.faq-accordion__item-label');
      labelElm.toggleClass('expanded');
    });

    function setHeightForCard(sections) {
      if (!sections || !sections.length) return;

      $(sections).each(function(idx, section) {
        const $cards = $(section).find('.js-title-slider');

        makeCardsSameHeight($cards);
      });
    }

    function makeCardsSameHeight($cards) {
      if (!$cards.length) {
        return;
      }

      $cards.height('auto');

      let maxHeight = $cards.height();
      $cards.each(function(index, el) {
        const heightCurrent = $(el).height();
        if (maxHeight < heightCurrent) {
          maxHeight = heightCurrent;
        }
      });

      $cards.height(maxHeight);
    }

    setHeightForCard($titleSliderParent);

    hashChange();

    function hashChange() {
      const accordion = location.hash;

      if (!accordion) {
        return;
      }

      const $accordion = $(accordion);

      if (!$accordion.hasClass('js-title-slider-parent')) {
        return;
      }

      const top = $accordion.offset().top;
      window.scrollTo(0, top);

      $accordion.find('.js-text-slider').slideToggle(600);
      $accordion.find('.js-title-icon').addClass('icon-minus').removeClass('icon-plus');
    }

    $(window).on('hashchange', function () {
      hashChange();
    });

    $(window).on('resize', function () {
      setHeightForCard($titleSliderParent);
    });
  });
});
