$(document).ready(function () {
  const sectionLifePlan = '.js-section-life-plans';
  const $slider = $('.js-slick-life-plan');
  const $filterItem = $('.js-filter-item');
  const $tabFilterActive = $('.js-tab-filter-active');
  const $dropdownMenu = $('.js-dropdown-menu');
  const $dropdownItems = $('.js-dropdown-items');
  const $activeFilter = $('.js-tab-filter.active');
  const allPlan = 'all';
  const tabFilter = '.js-tab-filter';
  const isActive = 'active';

  if ($activeFilter.length) {
    $activeFilter.each(function() {
      const $activeFilterItem = $(this).find('.js-filter-item');
      const selectedPlan = $activeFilterItem.data('id');
      if ($(window).outerWidth() >= 768) {
        tabFilterCardFading(selectedPlan);

      } else {
        // Need to wait till slick initialize again
        setTimeout(function(){
          slickFilterCard(selectedPlan);
        }, 100);
      }
    });
  }

  $(window).on('resize', function () {
    const selectedPlan = $(`${tabFilter}.active`).find('.js-filter-item').data('id');

    if ($(window).outerWidth() >= 768) {
      if ($slider.hasClass('slide-unslick')) {
        return;
      }
      $slider.slick('slickUnfilter', '[data-duration]');
      tabFilterShowHide(selectedPlan);
      $slider.addClass('slide-unslick');
      $slider.removeClass('slide-slick');
    } else {
      if ($slider.hasClass('slide-slick')) {
        return;
      }

      // Need to wait till slick initialize again
      setTimeout(function(){
        $('[data-duration]').show();
        $slider.addClass('slide-slick');
        $slider.removeClass('slide-unslick');
        slickFilterCard(selectedPlan);
      }, 100);
    }
  });

  function tabFilterShowHide(selectedPlan) {
    $('[data-duration]').hide();

    if (selectedPlan === allPlan) {
      $(sectionLifePlan).find('.js-card').show();
    } else {
      $(`[data-duration=${selectedPlan}]`).show();
    }
  }

  function tabFilterCardFading(selectedPlan) {
    $('[data-duration]').fadeOut(200);
    if (selectedPlan === allPlan) {
      $(sectionLifePlan).find('.js-card').fadeIn(200, function() {
        window.configColumnLayoutCard(sectionLifePlan);
      });
    } else {
      $(`[data-duration=${selectedPlan}]`).fadeIn(200, function() {
        window.configColumnLayoutCard(sectionLifePlan);
      });
    }
  }

  function slickFilterCard(selectedPlan) {
    $slider.slick('slickUnfilter', '[data-duration]');

    if (selectedPlan === allPlan) {
      $slider.slick('slickUnfilter');
    } else {
      $slider.slick('slickFilter', `[data-duration=${selectedPlan}]`);
    }
  }

  $dropdownItems.on('click', function(){
    $(this).find('.js-dropdown-icon').toggleClass('icon-arrow-down icon-arrow-up');
  });

  $filterItem.on('click', function () {
    const $self = $(this);
    const selectedPlan = $self.data('id');
    $(tabFilter).removeClass(isActive);
    $self.closest(tabFilter).addClass(isActive);
    let textPlan = $self.text();
    $tabFilterActive.text(textPlan);
    if ($(window).outerWidth() >= 768) {
      tabFilterCardFading(selectedPlan);
      window.setHeightForCard(sectionLifePlan, '.js-item-same-height');
      window.setHeightForCard(sectionLifePlan, '.price-plan-card__list-text');
      window.setHeightForCard(sectionLifePlan, '.price-plan-card__top-line');
    } else {
      slickFilterCard(selectedPlan);
      $dropdownItems.trigger('click');
    }
  });

  $tabFilterActive.on('click', function () {
    let widthBoardDropdown = $(this).outerWidth();
    $dropdownMenu.css('width', widthBoardDropdown);
  });
});
