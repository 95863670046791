$(document).ready(function () {
  const $reviewSlider = $('.js-review-slider');
  let isHovered = false;

  $reviewSlider.slick({
    slidesToScroll: 4,
    slidesToShow: 4,
    centerMode: true,
    centerPadding: '150px',
    swipeToSlide: true,
    arrows: false,
    dots: false,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1460,
        settings: {
          centerPadding: '100px',
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          centerPadding: '60px',
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          centerPadding: '30px',
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  $reviewSlider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    if (isHovered) {
      event.preventDefault();
    }
  });

  $reviewSlider.on('afterChange', function () {
    const $slider = $(this);
    setTimeout(function () {
      $slider.find('.js-matchHeight').matchHeight();
    }, 100);
  });

  $(window).on('load', function () {
    $reviewSlider.find('.js-matchHeight').matchHeight();
  });
});
