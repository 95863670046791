$(document).ready(function() {
  const contentInners = $('.js-adjust-height');
  contentInners.each(function() {
    const contentInner = $(this);
    const contentInnerHeight = contentInner.outerHeight();
    const dataMaxHeight = $(this).data('max-height');

    function adjustHeight() {
      if (contentInnerHeight <= dataMaxHeight) {
        contentInner.css('height', 'auto');
        contentInner.css('overflow', 'hidden');
      } else if (contentInnerHeight > dataMaxHeight) {
        contentInner.css('height', dataMaxHeight);
        contentInner.css('overflow', 'scroll');
      }
    }

    adjustHeight();

    $(window).on('resize', function () {
      adjustHeight();
    });
  });
});
