$(document).ready(function () {
  const $electBillInput = $('.js-elect-bill');
  const $selectOption = $('.js-select-option');
  const $btnViewMySavings = $('.js-view-my-savings');
  const $electricity = $('.js-electricity-range');
  const contentTooltip = $('.js-tooltip-box').html();
  const $step23 = $('.js-step-2-3');
  const $form = $btnViewMySavings.closest('form');
  const $viewMySaving = $('.js-view-my-savings');
  const $stepCalculator = $('.step-calculator');

  let valueNowLeft, valueNowRight;
  let valueRatio = parseFloat($electBillInput.attr('data-ratio'));
  let valueMin = parseFloat($electBillInput.attr('data-value-min'));
  let valueMax = parseFloat($electBillInput.attr('data-value-max'));
  let rangeValueDefault = $electBillInput.data('range-value');

  function hideArrowButton() {
    $viewMySaving.hide();
    $stepCalculator.addClass('hide-before-arrow');
  }

  function showArrowButton() {
    $viewMySaving.show();
    $stepCalculator.removeClass('hide-before-arrow');
  }

  if (!$electBillInput.length) {
    return;
  }

  const selectBill = $electBillInput.slider({
    id: 'electricity-range__input',
    min: +valueMin,
    max: +valueMax,
    tooltip: 'hide',
    value: rangeValueDefault
  });

  window.webshim.setOptions('forms', {
    lazyCustomMessages: true,
    addValidators: true,
    iVal: {
      errorMessageClass: 'input-error',
    }
  });

  $selectOption.on('change', function () {
    const option = $(this).find('option:selected');
    const $selectedOption = $(option[0]);
    const valueSliderDefault = $selectedOption.data('slider-value');
    showArrowButton();

    if (!$selectedOption.hasClass('js-empty')) {
      selectBill.slider('setValue', valueSliderDefault);
      tooltipContent();
      $step23.removeClass('hidden');
    }
  });

  $form.on('submit', function(event) {
    const form = $form[0];
    if (!form.checkValidity()) {
      event.preventDefault();
    }
  });

  $('.min-slider-handle, .max-slider-handle').html(contentTooltip);

  const $minSliderHandle = $electricity.find('.min-slider-handle');
  const $maxSliderHandle = $electricity.find('.max-slider-handle');

  tooltipContent();

  $electricity.on('change', sliderChangeHandler);

  function sliderChangeHandler() {
    tooltipContent();
  }

  $('.js-input-kwh').on('mousedown touchstart', function (e) {
    e.stopPropagation();
  });

  $('.js-input-pay').on('mousedown touchstart', function (e) {
    e.stopPropagation();
  });

  $('.tooltip-box').on('mousedown touchstart focus', function (e) {
    e.stopPropagation();
  });

  $('.min-slider-handle .js-input-kwh').on('change', setMinKwhValue);
  $('.max-slider-handle .js-input-kwh').on('change', setMaxKwhValue);

  $('.min-slider-handle .js-input-pay').on('change', setPayMinValueBill);
  $('.max-slider-handle .js-input-pay').on('change', setPayMaxValueBill);

  $(document).on('focusin', 'input', function(){
    $(this).data('val', $(this).val());
  });

  hideArrowButton();

  function setMinKwhValue() {
    const values = $electBillInput.attr('data-value').split(',');

    const minValue = parseFloat($(this).val());
    const maxValue = parseFloat(values[1]);

    const shouldSetValue = setValueAfterChangeValueInput(minValue, maxValue);

    if (shouldSetValue) {
      $minSliderHandle.find('.js-electricity-number').val(minValue + ' kWh');
      $minSliderHandle.find('.js-electricity-pay').val('$' + bill(minValue, valueRatio));
    } else {
      $minSliderHandle.find('.js-electricity-number').val($(this).data('val'));
    }

    setTimeout(function() {
      $electricity.on('change', sliderChangeHandler);
    });
  }

  function setMaxKwhValue() {
    const values = $electBillInput.attr('data-value').split(',');
    const maxValue = parseFloat($(this).val());
    const minValue = parseFloat(values[0]);

    const shouldSetValue = setValueAfterChangeValueInput(minValue, maxValue);

    if (shouldSetValue) {
      $maxSliderHandle.find('.js-electricity-number').val(maxValue + ' kWh');
      $maxSliderHandle.find('.js-electricity-pay').val('$' + bill(maxValue, valueRatio));
    } else {
      $maxSliderHandle.find('.js-electricity-number').val($(this).data('val'));
    }

    setTimeout(function() {
      $electricity.on('change', sliderChangeHandler);
    });
  }

  function setPayMinValueBill() {
    const values = $electBillInput.attr('data-value').split(',');
    const inputtedValue = $(this).val().replace('$', '');
    const minValue = Math.round(electricity(inputtedValue, valueRatio));
    const maxValue = parseFloat(values[1]);

    const shouldSetValue = setValueAfterChangeValueInput(minValue, maxValue);

    if (shouldSetValue) {
      $minSliderHandle.find('.js-electricity-number').val(minValue + ' kWh');
      $minSliderHandle.find('.js-electricity-pay').val('$' + parseFloat(inputtedValue).toFixed(2));
    } else {
      $minSliderHandle.find('.js-electricity-pay').val($(this).data('val'));
    }

    setTimeout(function() {
      $electricity.on('change', sliderChangeHandler);
    });
  }

  function setPayMaxValueBill() {
    $electricity.off('change', sliderChangeHandler);
    const valueBill = $electBillInput.attr('data-value').split(',');

    const inputtedValue = $(this).val().replace('$', '');
    const minValue = parseFloat(valueBill[0]);
    const maxValue = Math.round(electricity(parseFloat(inputtedValue), valueRatio));

    const shouldSetValue = setValueAfterChangeValueInput(minValue, maxValue);

    if (shouldSetValue) {
      $maxSliderHandle.find('.js-electricity-number').val(maxValue + ' kWh');
      $maxSliderHandle.find('.js-electricity-pay').val('$' + parseFloat(inputtedValue).toFixed(2));
    } else {
      $maxSliderHandle.find('.js-electricity-pay').val($(this).data('val'));
    }

    setTimeout(function() {
      $electricity.on('change', sliderChangeHandler);
    });
  }

  function setValueAfterChangeValueInput(min, max) {
    if (max < min || min < valueMin || max > valueMax || isNaN(min) || isNaN(max)) {
      return false;
    }

    selectBill.slider('setValue', [ min, max ]);


    return true;
  }

  function tooltipContent() {
    valueNowLeft = $minSliderHandle.attr('aria-valuenow');
    valueNowRight = $maxSliderHandle.attr('aria-valuenow');

    $minSliderHandle.find('.js-electricity-number').val(valueNowLeft + ' kWh');
    $minSliderHandle.find('.js-electricity-pay').val('$' + bill(valueNowLeft, valueRatio));
    $maxSliderHandle.find('.js-electricity-number').val(valueNowRight + ' kWh');
    $maxSliderHandle.find('.js-electricity-pay').val('$' + bill(valueNowRight, valueRatio));
  }

  function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }


  function bill(value, ratio) {
    return formatNumber( (value * ratio).toFixed(2));
  }

  function electricity(value, ratio) {
    return  value / ratio;
  }
  window.webshim.polyfill('forms');
});
