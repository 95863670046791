$(document).ready(function () {
  const planWithCalculator = $('#pricePlansCalculator')
  const openSlide = $('.js-open-slide, .js-open-slide-custom');
  const closeSlide = $('.js-close-slide');
  if (!planWithCalculator.length) return;

  function scrollToComponentCenter($component) {
    const componentOffset = $component.offset().top;
    const windowHeight = window.innerHeight;
    const componentHeight = $component.outerHeight();

    const scrollTo = componentOffset - (windowHeight - componentHeight) / 2;

    $('html, body').animate({
      scrollTop: scrollTo
    }, 500);
  }

  openSlide.on('click', function () {
    const clickedCard = $(this);
    const correspondingCard = clickedCard.closest('.promotions-card').find('.js-card-open, .js-card-open-custom');
    const cardWrapper = clickedCard.closest('.price-plan-calculator').find('.container-fluid');
    const card = clickedCard.closest('.price-plans__with-calculator').find('.price-plans__item');
    const cardSlider = clickedCard.closest('.price-plans__with-calculator').find('.price-plans__slider');
    const thisCard = clickedCard.closest('.price-plans__item');
    const thisCardInner = clickedCard.closest('.promotions-card');

    $('.js-card-open.card-open .js-close-slide, .js-card-open-custom.card-open .js-close-slide').trigger('click');
    correspondingCard.addClass('card-open');
    thisCard.addClass('open');
    card.not('.open').addClass('card-blur');
    thisCardInner.find('.featured-plan__card--icon, .featured-plan__card--triangle').css('opacity', '0');

    const windowWidth = $(window).width();
    const totalWidth = windowWidth - 20;

    if (window.innerWidth >= 992) {
      cardSlider.width(totalWidth + 'px');
      cardWrapper.css({ 'max-width': 'unset', 'padding': '0' });
      scrollToComponentCenter(thisCard);
    }
  });

  closeSlide.on('click', function () {
    const clickedCard = $(this);
    const correspondingCard = clickedCard.closest('.promotions-card').find('.js-card-open, .js-card-open-custom');
    const card = clickedCard.closest('.price-plans__with-calculator').find('.price-plans__item');
    const cardWrapper = clickedCard.closest('.price-plan-calculator').find('.container-fluid');
    const cardSlider = clickedCard.closest('.price-plans__with-calculator').find('.price-plans__slider');
    const thisCard = clickedCard.closest('.price-plans__item');
    const thisCardInner = clickedCard.closest('.promotions-card');

    $('.js-card-open.card-open').not(correspondingCard).removeClass('card-open');
    correspondingCard.removeClass('card-open');
    thisCard.removeClass('open');
    card.removeClass('card-blur');
    thisCardInner.find('.featured-plan__card--icon, .featured-plan__card--triangle').css('opacity', '1');

    if (window.innerWidth >= 992) {
      cardSlider.width('');
      cardWrapper.css({ 'max-width': '', 'padding': '' });
    }
  });

  $('.js-plan-input').each(function () {
    const inputValue = $(this).find('#cardInputField');
    const hiddenVal = $(this).find('#cardHiddenField');
    const spRate = $(this).attr('data-spRate');
    const planPrice = $(this).attr('data-planPrice');
    const monthChoice = $(this).attr('data-month');
    const certifiedSolar = $(this).attr('data-percent');
    const gridEmission = $(this).attr('data-grid-emission');
    const flatRabate = parseFloat($(this).attr('data-flat-rebate')) || 0;
    const minVal = parseFloat($(this).attr('data-minVal')) || 0;
    const maxVal = parseFloat($(this).attr('data-maxVal')) || 9999;
    const val = parseFloat($(this).attr('data-val')) || 420;
    const cardTitleClass = $(this).closest('.promotions-card__slide--top').parent().parent().prev('.promotions-card__border').find('.js-card-title');

    function updateSavingResult() {
      hiddenVal.val(inputValue.val());
      const enteredValue = parseFloat(inputValue.val()) || 0;

      if (enteredValue < minVal || enteredValue > maxVal) {
        inputValue.val(val);
      }

      const a = parseFloat(inputValue.val()) || 0;
      const b = parseFloat(spRate) || 0;
      const c = parseFloat(planPrice) || 0;
      const d = parseFloat(certifiedSolar) || 0;
      const e = parseFloat(gridEmission) || 0;
      const month = parseFloat(monthChoice) || 0;
      const promotionsCardMid = $(this).closest('.promotions-card__slide--top').next('.promotions-card__slide--mid');
      const savingResult = promotionsCardMid.find('.js-saving-result');
      const carbonResult = promotionsCardMid.find('.js-carbon-result');

      const difference = (a * b) - (a * c);
      const result = difference * month + flatRabate;
      const roundedResult = result.toFixed(2);

      const carbonSolar = a * d * e * month;
      const carbonTotal = Math.round(carbonSolar);

      savingResult.text(roundedResult);
      carbonResult.text(carbonTotal + 'kg');
    }

    function inputDataPush() {
      const cardTitle = cardTitleClass.text();
      const userValue = parseFloat(inputValue.val()) || 0;

      window.dataLayer.push({
        'event': 'pricePlanUsageInput',
        'price plan': cardTitle,
        'value': userValue
      });
    }

    let typingTimer;
    const doneTypingInterval = 1000;

    inputValue.on('input', function () {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(inputDataPush, doneTypingInterval);
    });

    inputValue.on('keyup', updateSavingResult);

    inputValue.on('click', function () {
      $(this).val("");
    });

    inputValue.trigger('keyup');
  });

  function setHeight(selector) {
    planWithCalculator.find(selector).css('height', 'auto');
    planWithCalculator.each(function () {
      const $pls = $(this).find(selector);
      const heights = $pls.map(function () {
        return $(this).height();
      }).get();
      const maxHeight = Math.max(...heights);
      $pls.height(maxHeight);
    })
  }

  setHeight('.featured-plan__card--content');
  setHeight('.js-cardHeight');

  $(window).on('resize', function () {
    setHeight('.featured-plan__card--content');
    setHeight('.js-cardHeight');
  });

  $(window).on('load', function () {
    setHeight('.featured-plan__card--content');
    setHeight('.js-cardHeight');
  });

  $(window).ready(function () {
    const $act = $('#selected-product.js-card');
    if ($act.length) {
      const $bt = $act.find(".js-open-slide");
      if ($bt.length) {
        $bt.trigger("click");
      }
      else {
        scrollToComponentCenter($act);
      }
    }

    const $card = planWithCalculator.find('.promotions-card__border');
    const width = $card.map(function () {
      return $(this).height();
    }).get();
    const maxwidth = Math.max(...width);
    $card.width(maxwidth);
  });
});
