$(document).ready(function () {
  const $sliderCarousel = $('.js-carousel-content');
  const $sliderCarouselTab = $('.js-carousel-tab');

  $('body').on('touchstart', function() {
    $sliderCarousel.slick('slickPlay');
    $sliderCarouselTab.slick('slickPlay');
  });

  let numSlick = 0;
  $sliderCarousel.each(function () {
    numSlick++;
    $(this).addClass('slider-' + numSlick).slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      autoplaySpeed: $(this).data('autoplaySpeed') || 5000,
      speed: 500,
      asNavFor: '.js-carousel-tab.slider-' + numSlick,
      focusOnSelect: true,
      pauseOnHover: true,
      responsive: [ {
        breakpoint: 768,
        settings: {
          dots: true,
          arrows: false,
          autoplay: true
        }
      } ]
    });
  });

  let numSlickTab = 0;
  $sliderCarouselTab.each(function () {
    numSlickTab++;
    $(this).addClass('slider-' + numSlickTab).slick({
      vertical: false,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: $(this).data('autoplaySpeed') || 5000,
      speed: 500,
      asNavFor: '.js-carousel-content.slider-' + numSlickTab,
      arrow: false,
      focusOnSelect: true,
      pauseOnHover: true,
      mobileFirst: true,
      responsive: [ {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
        }
      }, {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
          autoplay: true
        }
      } ]
    });
  });
});
