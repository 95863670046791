$(document).ready(function () {
  if (!$('.js-form-career-submit').length) {
    return;
  }
  // TODO: move to config

  const $jsConfirmEmail = $('.js-confirm-email');
  const $jsEmail = $('.js-email');
  const $newsLetter = $('.js-form-career-submit.newsletter-form-submit');

  const dateFormat = 'DD MMM YYYY';
  var currentDate = new Date();
  var $singleDate = $('.js-single-date');

  const attachedTemplateClassName = 'js-attached-template';
  const hiddenClassName = 'hidden';
  var uploadFileElements = [];

  createUploadFileElement = function createUploadFileElement($rootElement) {
    var $root = $rootElement;
    var $attachedTemplate = $root.find('.' + attachedTemplateClassName);
    var $attachedArea = $root.find('.js-attached-area');
    var $chooseFile = $root.find('.js-up-load-file');
    var $labelChooseFile = $root.find('.js-label-up-load');
    var $error = $root.find('.js-file-error');
    var $success = $root.find('.js-file-success');
    var $totalSizeError = $root.find('.js-file-limit');
    var $attachmentValidate = $root.find('input[name="attachmentValidate"]');
    var $submitGroup = $root.find('.career-form-submit__group');
    var validFileExtensions = $chooseFile.attr('accept');
    var validFileExtensions = validFileExtensions ? validFileExtensions.replace(/\s/g, '').toLowerCase().split(',') : undefined;
    return {
      $root: $root,
      $attachedTemplate: $attachedTemplate,
      $attachedArea: $attachedArea,
      $chooseFile: $chooseFile,
      $labelChooseFile: $labelChooseFile,
      $error: $error,
      $success: $success,
      $totalSizeError: $totalSizeError,
      $attachmentValidate: $attachmentValidate,
      $submitGroup: $submitGroup,
      currentId: $chooseFile.attr('id'),
      action: $labelChooseFile.data('action'),
      errorMsg: $attachmentValidate.data('errormessage'),
      validFileExtensions: validFileExtensions,
      limitedFileSize: parseInt($chooseFile.data('limit-size')),
      minFileUpload: parseInt($chooseFile.data('min-file')),
      maxFileUpload: parseInt($chooseFile.data('max-file')),
      maxLengthFileName: parseInt($chooseFile.data('max-length-name')),
      totalFileSize: parseInt($chooseFile.data('total-file-size')),
      attachFiles: []
    };
  };

  window.webshim.setOptions('forms', {
    lazyCustomMessages: true,
    addValidators: true,
    iVal: {
      errorMessageClass: 'input-error',
    }
  });

  $jsConfirmEmail.on('change', function () {

    const email = $jsEmail.val(),
      confirm = $jsConfirmEmail.val();

    if (email !== confirm) {
      $jsConfirmEmail.setCustomValidity('Confirmation email must match your email address');
      $jsConfirmEmail.trigger('updatevalidation');
    } else {
      $jsConfirmEmail.setCustomValidity('');
    }
  });

  $newsLetter.find('.js-newsletter-input').on('change', function () {
    const isCheckBoxValid = $(this).prop('checked');
    const isRadioValid = $(this).prop('checked');

    if (!isCheckBoxValid || !isRadioValid) {
      disableSubmitButton();
    } else {
      enableSubmitButton();
    }
  });

  $newsLetter.find('.js-newsletter-input').on('keyup', function () {
    const isEmailValid = $(this).val().trim() !== '';
    if (!isEmailValid) {
      disableSubmitButton();
    } else {
      enableSubmitButton();
    }
  })

  $newsLetter.on('submit', function (e) {
    e.preventDefault();

    if ($newsLetter[0].checkValidity()) {
      closeModal();

      setTimeout(function () {
        showSuccessModal();
      }, 200);
    } else {
      disableSubmitButton();
    }
  });

  function disableSubmitButton() {
    const submitButton = $newsLetter.find('.newsletter-form__submit input[type="submit"]');
    submitButton.prop('disabled', true);
  }

  function enableSubmitButton() {
    const submitButton = $newsLetter.find('.newsletter-form__submit input[type="submit"]');
    submitButton.prop('disabled', false);
  }

  disableSubmitButton();

  function closeModal() {
    $('#newsletter-modal').modal('hide');
  }

  function showSuccessModal() {
    $('#submitted-modal').modal('show');
  }

  window.webshim.polyfill('forms');

  function checkMaxFileUploaded(uploadFileElement) {
    if (!uploadFileElement.maxFileUpload) {
      return false;
    }

    return uploadFileElement.attachFiles.length >= uploadFileElement.maxFileUpload;
  }

  function validateUploadFieldButton(uploadFileElement) {
    if (checkMaxFileUploaded(uploadFileElement)) {
      if (!uploadFileElement.$labelChooseFile.hasClass('disabled')) uploadFileElement.$labelChooseFile.addClass('disabled');
      if (!uploadFileElement.attachFiles.length) uploadFileElement.$labelChooseFile.text(uploadFileElement.action.chooseFile);
    } else {
      if (uploadFileElement.$labelChooseFile.hasClass('disabled')) uploadFileElement.$labelChooseFile.removeClass('disabled');
      if (uploadFileElement.attachFiles.length) uploadFileElement.$labelChooseFile.text(uploadFileElement.action.chooseAnotherFile);
      else uploadFileElement.$labelChooseFile.text(uploadFileElement.action.chooseFile);
    }

    if (uploadFileElement.attachFiles.length > 0) {
      uploadFileElement.$attachmentValidate.prop('checked', true);
    } else {
      uploadFileElement.$attachmentValidate.prop('checked', false);
    }

    uploadFileElement.$attachmentValidate.trigger('change');
  }

  function convertSize(originSize) {
    let mbSize = originSize / 1048576;
    let unit = 'MB';

    if (mbSize < 0.01) {
      mbSize = Math.round(originSize);
      unit = 'Bytes';
    } else if (mbSize < 1) {
      mbSize = mbSize * 1024;
      mbSize = mbSize.toFixed(2);
      unit = 'KB';
    } else {
      mbSize = mbSize.toFixed(2);
    }

    return {
      size: mbSize,
      unit: unit,
    };
  }

  function numberWithCommas(x) {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }

  function removeFile(uploadFileElement, file, htmlFile, index) {
    $(htmlFile).remove();
    uploadFileElement.attachFiles = uploadFileElement.attachFiles.filter(function (attachFile) {
      return attachFile.name !== file.name;
    });
    validateUploadFieldButton(uploadFileElement);
    hideMessageFileError(uploadFileElement);

    if (index === 0) {
      uploadFileElement.$chooseFile.val('');
    } else {
      $('#' + uploadFileElement.currentId + '-' + index).val('');
    }

    switchInputFile(uploadFileElement);
  }

  function readFile(uploadFileElement, file) {
    var fileReader = new FileReader();

    fileReader.onload = function () {
      file.base64Content = fileReader.result.split('base64,')[1];
      uploadFileElement.attachFiles.push(file);

      generateHTMLForFileUpload(file, uploadFileElement);
      validateUploadFieldButton(uploadFileElement);
    };

    fileReader.readAsDataURL(file);
  }

  function generateHTMLForFileUpload(file, uploadFileElement) {
    var index = uploadFileElement.attachFiles.length - 1;
    var fileSize = convertSize(file.size);
    var htmlFile = uploadFileElement.$attachedTemplate.clone();

    htmlFile.removeClass(attachedTemplateClassName);
    htmlFile.removeClass(hiddenClassName);
    htmlFile.find('.js-file-name').html(file.name);
    htmlFile.find('.js-file-size').html(numberWithCommas(fileSize.size) + ' ' + fileSize.unit);
    htmlFile.find('.js-remove-file').on('click', removeFile.bind(this, uploadFileElement, file, htmlFile, index));
    htmlFile.appendTo(uploadFileElement.$attachedArea);
  }

  function hideMessageFileError(uploadFileElement) {
    uploadFileElement.$error.empty();
    uploadFileElement.$error.hide();
  }

  function showMessageFileError($error, msg) {
    $error?.show().text(msg);
  }

  function showMessageFileSuccess($success, msg) {
    $success?.show().text(msg);
  }

  function loadFileInput(uploadFileElement, event) {
    hideMessageFileError(uploadFileElement);
    var file = event.target.files[0] || undefined;

    if (!file) {
      return;
    }

    if (file.size > uploadFileElement.limitedFileSize) {
      showMessageFileError(uploadFileElement.$error, uploadFileElement.errorMsg.fileSizeExceed);
      return;
    }

    var fileExtension = '.' + file.name.split('.').pop();
    var fileType = file.type;

    if (uploadFileElement.validFileExtensions
      && uploadFileElement.validFileExtensions.indexOf(fileType.toLowerCase()) === -1
      && uploadFileElement.validFileExtensions.indexOf(fileExtension.toLowerCase()) === -1) {
      showMessageFileError(uploadFileElement.$error, uploadFileElement.errorMsg.extensionWrong);
      return;
    }

    if (uploadFileElement.maxLengthFileName && file.name.length > uploadFileElement.maxLengthFileName) {
      showMessageFileError(uploadFileElement.$error, uploadFileElement.errorMsg.fileNameTooLong);
      return;
    }

    if (checkMaxFileUploaded(uploadFileElement)) {
      showMessageFileError(uploadFileElement.$error, uploadFileElement.errorMsg.numberOfFilesExceed);
      return;
    }

    switchInputFile(uploadFileElement);
    readFile(uploadFileElement, file);
    showMessageFileSuccess(uploadFileElement.$success, uploadFileElement.errorMsg.uploadSuccess);
    uploadFileElement.$labelChooseFile.text(uploadFileElement.action.chooseAnotherFile);
  }

  function switchInputFile(uploadFileElement) {
    if (uploadFileElement.maxFileUpload <= 1) return;

    if (!uploadFileElement.$chooseFile.val()) {
      uploadFileElement.$labelChooseFile.attr('for', uploadFileElement.currentId);

      return;
    }
    var id;
    for (var i = 1; i < uploadFileElement.maxFileUpload; i++) {
      id = uploadFileElement.currentId + '-' + i;
      if (!$('#' + id).val()) break;
    }
    uploadFileElement.$labelChooseFile.attr('for', id);
  }

  function generateInputFile() {
    var $root = $('.js-attachment');
    if (!$root.length) return;
    $root.each(function (index, e) {
      var uploadFileElement = createUploadFileElement($(e));
      if (!uploadFileElement.$chooseFile || uploadFileElement.$chooseFile.length < 1) return;
      
      uploadFileElement.$chooseFile.on('change', loadFileInput.bind(this, uploadFileElement));
      uploadFileElement.$chooseFile.on('disabledchange', onChooseFileAttributeChange.bind(this, uploadFileElement));
      onChooseFileAttributeChange(uploadFileElement)
      uploadFileElements[index] = uploadFileElement;
      if (uploadFileElement.maxFileUpload < 1) return;

      for (var i = 1; i < uploadFileElement.maxFileUpload; i++) {
        var $newInputFile = uploadFileElement.$chooseFile.clone();
        var id = uploadFileElement.currentId + '-' + i;
        $newInputFile.attr('id', id);
        $newInputFile.insertAfter(uploadFileElement.$chooseFile);
        $newInputFile.on('change', loadFileInput.bind(this, uploadFileElement));
      }
    });
    $root.closest('form').on('submit', onSubmitAction);
  }

  function onChooseFileAttributeChange(uploadFileElement, event) {
    var choose = uploadFileElement.$chooseFile;
    var label = uploadFileElement.$labelChooseFile;
    var $attachments = uploadFileElement.$attachedArea.find('.career-form-submit__item');
    if (choose.prop('disabled')) {
        if(!label.hasClass('disabled')){
          label.addClass('disabled');
        }
        $attachments.each(function(index, attachment){
          var $e = $(attachment);
          if (!$e.hasClass('js-attached-template')) {
            $e.addClass('hidden');
          }
        });
      }
      else if (!choose.prop('disabled')) {
        if(label.hasClass('disabled') && (uploadFileElement.attachFiles.length < uploadFileElement.maxFileUpload)) {
          label.removeClass('disabled');
        }
        $attachments.each(function(index, attachment){
          var $e = $(attachment);
          if (!$e.hasClass('js-attached-template')) {
            $e.removeClass('hidden');
          }
        });  
      }
  }

  function onSubmitAction(e) {
    for (var index = 0; index < uploadFileElements.length; index++) {
      // set to default
      var uploadFileElement = uploadFileElements[index];      
      if(!uploadFileElement.$chooseFile || uploadFileElement.$chooseFile.prop('disabled')) {
        var remove = uploadFileElement.$attachedArea.find('.career-form-submit__item .js-remove-file');
        remove.trigger( "click" );
      }

      if (uploadFileElement.attachFiles.length < uploadFileElement.minFileUpload) {
        uploadFileElement.$attachmentValidate.prop('checked', false);
      }

      var totalSize = 0;
      uploadFileElement.$chooseFile.each(function (index, file) {
        totalSize += file.files[0] ? file.files[0].size : 0;
      });

      if (uploadFileElement.totalFileSize < totalSize) {
        uploadFileElement.$totalSizeError.show();
        e.preventDefault();
        return;
      }
    }

    if (!this.checkValidity()) {
      e.preventDefault();
    }
  }

  generateInputFile();

  function initializeDate(dateEls, currentDate) {
    dateEls.each(function (idx, dateEl) {
      var emptyDefaultDate = $singleDate.data('empty-default-date');
      var allowUpdate = !emptyDefaultDate;

      $(dateEl).daterangepicker({
        opens: 'center',
        autoApply: true,
        singleDatePicker: true,
        startDate: currentDate,
        endDate: currentDate,
        minDate: currentDate,
        maxDate: new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDay()),
      });

      $(dateEl).on('apply.daterangepicker', function (ev, picker) {
        allowUpdate = true;

        var selectedDate = picker.startDate.format(dateFormat);

        $(dateEl).val(selectedDate);
      });

      $(dateEl).on('change', function () {
        if (!allowUpdate) {
          $(this).val('');
        }
      });

      $(dateEl).val(emptyDefaultDate ? '' : moment(currentDate).format(dateFormat));
    });
  }

  initializeDate($singleDate, currentDate);

  $(document).on('singleDate:created', function (e, newDateField, currentDate) {
    if (!currentDate) {
      currentDate = new Date();
    }

    initializeDate($(newDateField), new Date(currentDate));
  });

  $(document).on('click', '.js-icon-date', function (e) {
    e.preventDefault();
    var $el = $(e.target).siblings('input.js-single-date');
    if($el.prop('disabled')) return;

    $(this).parent().find('input[type=text]').focus();
  });
});
