// $(document).ready(function () {
//   const $jsFilterCategory = $('.js-filter-category');
//   const $toggleCollapse = $('.js-filter-category [data-toggle="collapse"]');
//   const $multiCollapse = $('.js-filter-category .multi-collapse');

//   $toggleCollapse.removeClass('collapsed');

//   $jsFilterCategory.on('hide.bs.collapse', function () {
//     if ($(window).outerWidth() >= 768) {
//       return false;
//     } else {
//       return true;
//     }
//   });

//   if ($(window).outerWidth() < 768) {
//     $jsFilterCategory.data('is-mobile', true);
//   } else {
//     $jsFilterCategory.data('is-mobile', false);
//   }

//   $(window).resize(function() {
//     if ($(window).outerWidth() < 768) {
//       if (!$jsFilterCategory.data('is-mobile')) {
//         $multiCollapse.collapse('hide');
//         $jsFilterCategory.data('is-mobile', true);
//       }
//     } else {
//       if ($jsFilterCategory.data('is-mobile')) {
//         $multiCollapse.collapse('show');
//         $jsFilterCategory.data('is-mobile', false);
//       }
//     }
//   });
// });
