$(document).ready(function () {
  const $parallax = $('#parallax');

  if (!$parallax.length) {
    return;
  }

  const containerWidth = 1144;
  const $document = $(document);
  const $groupTemplate = $($('#parallax-group-template').html());
  const $parallaxImageTemplate = $($('#parallax-image').html());

  const groupHeight = 1000;
  const circleConfigs = {
    colors: [ '#16b09c', '#eb6028', '#16b09c' ],
    positions: [ 'fore', 'base', 'back', 'deep' ],
  };

  generateCircles();

  function generateCircles() {
    const pageHeight = $document.height();

    const groupDistribution = Math.max(Math.ceil(pageHeight/groupHeight), 1);

    for (let i = 0; i < groupDistribution; i++) {
      const groupIndex = i + 1;
      createCircle(groupIndex);
    }
  }

  function createCircle(groupIndex) {
    const configs = getGroupConfigs();
    const $group = $groupTemplate.clone();
    $group.attr('id', `parallax-group-${groupIndex}`);

    const easing = new window.BezierEasing(0, 0, 1, 0.25);

    for (let i = 0; i < 3; i++) {
      const position = circleConfigs.positions[i];
      const $layer = $group.find(`.js-parallax-layer-${position}`);
      const $positionLayer = $layer.find('.js-parallax-position');

      const distance = Math.random() * 500;
      const diameter = Math.ceil(100 + easing(distance / 500) * 400);
      const y = Math.pow(-1, i) * (distance + (containerWidth / 2));

      $positionLayer.css({
        'margin-left': `${y}px`,
        top: `${i * 22}%`,
        position: 'absolute'
      });
      const $image = $parallaxImageTemplate.clone();
      $positionLayer.append($image);

      $image.css({ 'width': `${diameter}px` });
      const $path = $image.find('.js-parallax-path');

      $path.css({ 'fill': `${configs.colors[i]}` });
    }

    $parallax.append($group);
  }

  function getGroupConfigs() {
    const numberOfCircles = 5; //Math.floor(Math.random() * 3) + 2;
    const results = {
      numberOfCircles,
    };

    for (let i = 0; i < numberOfCircles; i++) {
      results.colors = getRandomElementOfArray(circleConfigs.colors, numberOfCircles);
    }

    return results;
  }

  function getRandomElementOfArray(array, numberOfCircles) {
    const clonedArray = array.slice();
    const results = [];
    for (let i = 0; i < numberOfCircles; i++) {
      const index = Math.floor(Math.random() * clonedArray.length);
      results.push(clonedArray[index]);
      clonedArray.splice(index, 1);
    }

    return results;
  }

  function parallaxRendering() {
    $parallax.scrollTop(window.scrollY);
    window.requestAnimationFrame(parallaxRendering);
  }

  window.requestAnimationFrame(parallaxRendering);
});
