$(document).ready(function () {
  const allPlan = 'all';
  const tabFilter = '.js-tab-filter-plan';
  const isActive = 'active';
  const $tabContainer = $('.js-plan-tab-container');

  $tabContainer.each(function () {
    const $this = $(this);
    const sectionTabCard = $this.find('.js-section-tab-card');
    const $slider = $this.find('.js-slick-card-plan');
    const $filterItem = $this.find('.js-filter-item');
    const $activeFilter = $this.find('.js-tab-filter-plan.active');
    const componentId = $this.attr('id');

    function initializeSlick() {
      let numSlick = 0;

      $slider.each(function () {
        numSlick++;
        const sliderClass = 'featured-plan__card--wrap';
        const hasClass = $(this).hasClass(sliderClass);

        $(this).addClass('slider-' + numSlick).slick({
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          mobileFirst: true,
          centerMode: hasClass,
          centerPadding: '10%',
          variableWidth: true,
          arrows: false,
          dots: false,
          swipe: true,
          responsive: [
            {
              breakpoint: 991,
              settings: hasClass ? 'slick' : 'unslick'
            }
          ]
        });
      });
    }

    initializeSlick();

    if ($activeFilter.length) {
      $activeFilter.each(function () {
        const $activeFilterItem = $activeFilter.find('.js-filter-item');
        const selectedPlan = $activeFilterItem.data('id');

        if ($(window).outerWidth() >= 992) {
          tabFilterCardFading(selectedPlan);
        } else {
          setTimeout(function () {
            slickFilterCard(selectedPlan);
          }, 100);
        }
      });
    }

    $(window).on('resize', function () {
      const selectedPlan = $this.find(`${tabFilter}.active`).find('.js-filter-item').data('id');

      if ($(window).outerWidth() >= 992) {
        if ($slider.hasClass('slide-unslick')) {
          return;
        }
        $slider.slick('slickUnfilter', '[data-plan]');
        tabFilterShowHide(selectedPlan);
        $slider.addClass('slide-unslick');
        $slider.removeClass('slide-slick');
      } else {
        if ($slider.hasClass('slide-slick')) {
          return;
        }
        initializeSlick();

        setTimeout(function () {
          $this.find('[data-plan]').show();
          $slider.addClass('slide-slick');
          $slider.removeClass('slide-unslick');
          slickFilterCard(selectedPlan);
        }, 100);
      }
    });

    function tabFilterShowHide(selectedPlan) {
      sectionTabCard.find('[data-plan]').hide();

      if (selectedPlan === undefined || selectedPlan === allPlan) {
        sectionTabCard.find('.js-card').show();
      } else {
        sectionTabCard.find(`[data-plan=${selectedPlan}]`).show();
      }
    }

    function tabFilterCardFading(selectedPlan) {
      sectionTabCard.find('[data-plan]').fadeOut(30);

      if (selectedPlan === allPlan) {
        sectionTabCard.find('.js-card').fadeIn(30, function () {
          window.configColumnLayoutCard(sectionTabCard);
        });
      } else {
        sectionTabCard.find(`[data-plan=${selectedPlan}]`).fadeIn(30, function () {
          window.configColumnLayoutCard(sectionTabCard);
        });
      }

      if (componentId === 'featuredPlanCards') {
        slickFilterCard(selectedPlan);
      }
    }

    function slickFilterCard(selectedPlan) {
      $slider.slick('slickUnfilter', '[data-plan]');

      if (selectedPlan === allPlan) {
        $slider.slick('slickUnfilter');
      } else {
        $slider.slick('slickFilter', `[data-plan=${selectedPlan}]`);
      }
    }

    function closeExpandedView() {
      $('.js-card-open.card-open').removeClass('card-open');
      $('.price-plans__item').removeClass('open card-blur');
      $('.featured-plan__card--icon, .featured-plan__card--triangle').css('opacity', '1');
      $('.price-plan-calculator').find('.container-fluid').css({ 'max-width': '', 'padding': '' });
      $('.price-plans__with-calculator').find('.price-plans__slider').width('');
    }

    let activeTab = $activeFilter.length ? $activeFilter.data('id') : allPlan;

    $filterItem.on('click', function () {
      const $self = $(this);
      const selectedPlan = $self.data('id');

      if (selectedPlan !== activeTab) {
        activeTab = selectedPlan;

        $this.find(tabFilter).removeClass(isActive);
        $self.closest(tabFilter).addClass(isActive);

        $this.find('.slide-in-component').removeClass('slide-in');
        $this.find('.slide-in-card').removeClass('slide-in');
        slideInComponent();
        $slider.slick("slickSetOption", "swipe", true, false);
        $slider.slick('slickGoTo', 0);
        closeExpandedView();

        if ($(window).outerWidth() >= 992) {
          tabFilterCardFading(selectedPlan);
        } else {
          slickFilterCard(selectedPlan);
          initializeSlick();
        }
      }
    });

    $('.js-open-slide').on('click', function () {
      const clickedCard = $(this);
      const cardSlider = clickedCard.closest('.price-plans__with-calculator').find('.price-plans__slider');
      const thisCard = clickedCard.closest('.price-plans__item');
      const cardIndex = thisCard.index();

      cardSlider.slick('slickGoTo', cardIndex);
      $slider.slick("slickSetOption", "swipe", false, true);
    });

    $('.js-close-slide').on('click', function () {
      $slider.slick("slickSetOption", "swipe", true, false);
    });

    function slideInComponent() {
      const windowHeight = $(window).height();
      const slideInComponents = $this.find('.slide-in-component');
      const slideInCard = $this.find('.slide-in-card');

      function applySlideInEffect(elements) {
        elements.each(function () {
          const position = $(this).offset().top;

          if (position < $(window).scrollTop() + windowHeight && !$(this).hasClass('slide-in')) {
            const delay = $(this).data('delay');
            $(this).css('animation-delay', delay + 's');
            $(this).addClass('slide-in');
          } else if (position >= $(window).scrollTop() + windowHeight && $(this).hasClass('slide-in')) {
            $(this).removeClass('slide-in');
          }
        });
      }

      applySlideInEffect(slideInComponents);
      applySlideInEffect(slideInCard);
    }

    slideInComponent();

    $(window).scroll(function () {
      slideInComponent();
    });
  });
});
