$(document).ready(function () {
  function isMobileView() {
    return $(window).width() <= 767;
  }

  function toggleSubmenu() {
    const toggleMenu = $('.toggle-menu');
    const footerSubmenu = $('.footer__submenu');

    if (isMobileView()) {
      footerSubmenu.hide();
      toggleMenu.off('click');

      toggleMenu.click(function () {
        $(this).toggleClass('open');
        $(this).next(footerSubmenu).slideToggle('fast');
      });
    } else {
      toggleMenu.off('click');
      footerSubmenu.show();
    }
  }

  toggleSubmenu();

  if ($('.discover').length > 0) {
    $('.footer').addClass('footer__discover');
  } else {
    $('.footer').removeClass('footer__discover');
  }

  $(window).resize(function () {
    toggleSubmenu();
  });
});
