/* eslint-disable eqeqeq */
/* global CryptoJS */

(function (global) {
  global.encrypt = (myurl) => {
    var queryString = window.location.search;

    var _source = global.getParameterByName('source') !== null ? global.getParameterByName('source') : ''; // New Line for Renewal
    var _planRefNo = global.getParameterByName('planRefNo') !== null ? global.getParameterByName('planRefNo') : ''; // New Line for Renewal

    if (queryString.indexOf('?') > -1) {
      queryString = queryString.substring(1);
      myurl = myurl + '&' + queryString;
    }

    var key = CryptoJS.enc.Utf8.parse('85d0bb327ab0856c');
    var iv = CryptoJS.enc.Utf8.parse('85d0bb327ab0856c');

    var encryptedlogin = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(myurl), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }
    );

    encryptedlogin = encodeURIComponent(encryptedlogin);
    window.location.href = window.senoko.config.myaccount_url + encryptedlogin;
  };

  global.translateToAscii = (input, exponent) => {
    var temp = '';
    var result = '';

    //append a percent sign to trigger the final evaluation
    input = input + '%';

    for (var i = 0; i < input.length; i++) {
      if (input.charAt(i) != '%') {
        temp += input.charAt(i);
      } else if (temp != '') {
        var running = 0;
        var notMatchedYet = true;
        for (var j = 0, k = temp.length - 1; k >= 0; j++, k--) {
          if ((temp.match('\\n')) && (notMatchedYet)) {
            k--;
            notMatchedYet = false;
          }
          var partial = temp.charAt(j) * Math.pow(exponent, k);
          running += partial;
        }
        result += String.fromCharCode(running);
        temp = '';
      }
    }

    return result;
  };

  //Function get called by function on top named: getCompanyEmail
  global.obscureEmail = (text) => {
    var key = CryptoJS.enc.Base64.parse('#base64Key#');
    var iv = CryptoJS.enc.Base64.parse('#base64IV#');

    var decrypted = CryptoJS.AES.decrypt(text, key, { iv: iv });
    return decrypted.toString(CryptoJS.enc.Utf8);
  };

  global.getCompanyEmail = (choice, rtn) => {
    var theEmail = '';

    switch (choice) {
      case 'enquiry':
        theEmail = '0B3AR3j2yfDpgpWEvJKf1X0P4WpTmZ82HyEg6aSMGiw=';
        break;
      case 'dpo':
        theEmail = '4R3xjE/tQ2Zu/rzIYMs1EFhds0ITlOzqlaDnQUuB9DI=';
        break;
      case 'procteam':
        theEmail = 'yG1+8FyypyUIswqHesN9hVG+yrZLw6Fbz/5/nuNbviY=';
        break;
      case 'corpcomms':
        theEmail = 'VJwJSGGDgdicQDsYoDx+CUlkj30ueKyjEcYOWuRBB5U=';
        break;
      case 'spark':
        theEmail = 'FHA2yZQWjtUam8TiRcVsn7S72+sIteUFcd3TOBY7EFg==';
        break;
      case 'career':
        theEmail = '2hKFIHEH9N46coBDmKfh/6zmTZwIVHBJW9hwbiJF3L4=';
        break;
      case 'marketing':
        theEmail = 'AlIghkDddO/x7F3SggWsnwV1pMRtP4S7Nt7n25f1iyg=';
        break;
      case 'sales':
        theEmail = 'dVH1fzQikSmB7bZxnZjV/YF0ulnLDG4jTt+iEeplAMQ=';
        break;
      default:
        return null;
    }

    switch (rtn) {
      case 'write':
        document.write(global.obscureEmail(theEmail));
        break;
      case 'mailto':
        window.location = 'mailto:' + global.obscureEmail(theEmail);
        break;
      default:
        return global.obscureEmail(theEmail);
    }
  };

  // parse query string
  global.getParameterByName = (name, url) => {
    if (!url) url = window.location.href;

    // eslint-disable-next-line no-useless-escape
    name = name.replace(/[\[\]]/g, '\\$&');

    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);

    if (!results) return null;
    if (!results[2]) return '';

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  global.getReferPromoSecretKey = () => {
    var $ssk = CryptoJS.enc.Utf8.parse('V9T696QZ3_cwsr$6Tcg*r4jdq2FdEmAwxY!y8!UXtXE@xGsBh7');
    return $ssk.toString();
  }
})(window);
